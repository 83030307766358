import React from "react";
import { Link } from "react-router-dom";

import PreplacedLogoIcon from "../../assets/preplaced-logo-svg.svg";

// Stylesheet
import "./preplaced-logo.styles.scss";

const PreplacedLogo = () => {
  return (
    <span className="preplacedLogo">
      <Link to="/">
        <img src={PreplacedLogoIcon} alt="Preplaced" />

        <span>MyInterviewDoc</span>
      </Link>
    </span>
  );
};

export default PreplacedLogo;
