import {action, autorun, computed, observable} from "mobx";
import randomatic from "randomatic";

class PrefetchEditorViewModel{
    @observable isLoading = true; 
    @observable isDocAvailable = false;
    constructor({editorStore, docStore}){
        this.editorStore = editorStore;
        this.docStore = docStore;
        autorun(() => {
            const hash = window.location.hash.replace(/#/g, "");
          let [docKey, searchParams] = hash.split("?");
          console.log("dockey ", docKey)
          console.log("searchParams ", searchParams)

          window.history.pushState('',document.title, window.location.href.split("?")[0]);
          let docTempPasscode;
            searchParams = new URLSearchParams(searchParams);
            const onFailure = () => {
              console.log("doc failure")

              this.isLoading = false;
              this.isDocAvailable = false;
            }
            
            const onOwnerFailure = () => {
              this.isLoading = false;
              this.isDocAvailable = true;
              if (searchParams.get('passcode') && searchParams.get('passcode') === docTempPasscode){
                this.editorStore.setIsAuthenticated(true);
              }
            };

            const onOwnerSuccess = () => {
              this.isLoading = false;
              this.isDocAvailable = true;  
              this.editorStore.setIsAuthenticated(true);
            };

            const onSuccess = (docDetails) => {
              docTempPasscode = docDetails.passcode;
              this.docStore.checkIfUserOwnsTheDoc(docKey, onOwnerSuccess, onOwnerFailure);
            };

            const createNewDoc = () => {
              let params = {
                hash: docKey,
                passcode: searchParams.get('passcode') || randomatic("0", 6),
                name: searchParams.get('name') || 'untitled',
                goalSettingCount: parseInt(searchParams.get('gsCount') || 0),
                consultingSessionCount: parseInt(searchParams.get('csCount') || 0),
                mockInterviewCount: parseInt(searchParams.get('miCount') || 1),
              }
              this.docStore.createNewDocWithPredefinedValues(params, onOwnerSuccess, onFailure);
            }

            if (docKey) {
              this.docStore.checkIfDocExists(docKey, onSuccess, createNewDoc);
            } else {
              onFailure();
            }
        })
    } 

    @computed get isAuthenticated(){
        return  this.editorStore.isAuthenticated;
    }

    @action setIsAuthenticated = (val) => {
        this.editorStore.setIsAuthenticated(val)
    }

    @action checkIfUserOwnsTheDoc = (hash, onOwnerSuccess, onOwnerFailure) => {
        this.docStore.checkIfUserOwnsTheDoc(hash, onOwnerSuccess, onOwnerFailure);
    }

    @action checkIfDocExists = (hash, onSuccess, onFailure) => {
        this.docStore.checkIfDocExists(hash, onSuccess, onFailure);
    }
}

export default PrefetchEditorViewModel;