import React, { useState, useRef, useEffect } from "react";

// Mobx
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";

// Material-UI
import { IconButton, Button, MenuItem } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckIcon from "@material-ui/icons/Check";

// Stylesheets
import "./section-dropdown.styles.scss";
import SectionDropdownViewModel from "./SectionDropdownViewModel";

const SectionDropdown = ({ docStore, editorStore, walkthroughStore }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [viewModel, setViewModel] = useState({})
  const sectionNameRef = useRef(null);

  useEffect(() => {
    const sectionDropdownViewModel = new SectionDropdownViewModel({docStore, editorStore, walkthroughStore})
    setViewModel(sectionDropdownViewModel);
  }, [])

  const handleFocusEvent = (e) => {
    setIsEditMode(true);
  };

  const handleBlurEvent = (e) => {
    setIsEditMode(false);
  };

  useEffect(() => {
    if (sectionNameRef) {
      sectionNameRef.current.addEventListener("focus", handleFocusEvent);
      sectionNameRef.current.addEventListener("blur", handleBlurEvent);
    }
  }, [sectionNameRef]);

  const handleClick = (e) => {
    if (sectionNameRef) {
      isEditMode
        ? sectionNameRef.current.blur()
        : sectionNameRef.current.focus();
    }
  };


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sectionNameRef.current.blur();
    }
  };


  return useObserver(() => (
    <div
      className="sectionDropdown__sectionDropdownContainer"
      id="sectionDropdown"
    >
      <div
        className={`sectionDropdown__currentSectionWrapper ${
         viewModel.isOpen ? "sectionDropdown__currentSectionWrapper--open" : ""
        }`}
      >
        <input
          className="sectionDropdown__currentSectionName"
          value={viewModel.currentSectionInput}
          onChange={(e) => viewModel.handleChange(e)}
          onKeyDown={handleKeyDown}
          onBlur={viewModel.saveName}
          placeholder="Enter section name"
          ref={sectionNameRef}
        />

        <span className="sectionDropdown__sectionOptions">
          <IconButton onClick={handleClick}>
            {isEditMode ? <CheckIcon /> : <EditIcon />}
          </IconButton>

          <Button onClick={viewModel.toggleDropdown}>
            <ExpandMoreIcon className="custom-size-dropdown" />
          </Button>
        </span>

        {/* Menu Items - contains all the sections, and add button */}
        <div
          className={`sectionDropdown__dropdownBox ${
            viewModel.isOpen ? "sectionDropdown__dropdownBox--open" : ""
          }`}
        >
          {viewModel.sections && Object.keys(viewModel.sections).map((section) => (
            <div
              key={section}
              className={
                viewModel.currentSection === section
                  ? "dropdownBox__item dropdownBox__item--disabled"
                  : "dropdownBox__item"
              }
            >
              <div
                onClick={() => {
                viewModel.changeCurrentSection(section);
                }}
              >
                {viewModel.sections && viewModel.sections[section].name ||
                  viewModel.sections && viewModel.sections[section].type ||
                  section}
              </div>
              <IconButton
                onClick={() => {
                  viewModel.removeSelectedSection(section);
                }}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          ))}

          <MenuItem className="dropdownBox__item" onClick={viewModel.addNewInterview}>
            Add interview <AddIcon />
          </MenuItem>
        </div>
      </div>

      {/* Modal black background */}
      <div
        onClick={viewModel.toggleDropdown}
        className={`dropdownBox__backgroundFrame ${
          viewModel.isOpen ? "dropdownBox__backgroundFrame--open" : ""
        }`}
      ></div>
    </div>
  ));
};

export default compose(
  inject("docStore"),
  inject("editorStore"),
  inject("walkthroughStore")
)(SectionDropdown);