import {computed} from "mobx"

class SignupViewModel{
    constructor({userStore}){
        this.userStore = userStore;
    } 

    @computed get currentUser(){
        return  this.userStore.currentUser
    }
}

export default SignupViewModel 