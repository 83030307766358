import React from "react";

// Material-UI
import { Skeleton } from "@material-ui/lab";

// Stylesheet
import "./editor-cards-preview.styles.scss";

const EditorCardsPreview = () => {
  return (
    <div className="editorCardsPreview">
      {/* Header */}
      <div className="editorCardsPreview__header">
        <span className="header__leftSidePreview">
          <span className="header__logoPreview">
            <Skeleton width={48} height={48} variant="circle" />
          </span>
          <span className="header__docNamePreview">
            <Skeleton width={200} height={42} variant="text" />
          </span>
        </span>

        <span className="header__rightSidePreview">
          <Skeleton width={420} height={42} variant="text" />
          <Skeleton width={120} height={42} variant="text" />
          <Skeleton width={120} height={42} variant="text" />
        </span>
      </div>

      {/* Editor Section */}
      <div className="editorCardsPreview__content">
        <div className="content__leftSide">
            <Skeleton width="100%" height="100%" />
        </div>
        <div className="content__rightSide">
          <Skeleton height="100%" />
        </div>
      </div>
      
    </div>
  );
};

export default EditorCardsPreview;
