import {computed} from "mobx"

class InfomodalViewModel{
    constructor({docStore}){
        this.docStore = docStore;
    } 

    @computed get passcode(){
        return this.docStore.passcode
    }
}

export default InfomodalViewModel 