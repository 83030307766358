import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { inject, useObserver } from "mobx-react";
import { compose } from "recompose";
import randomatic from "randomatic";

// Material-UI
import { Button } from "@material-ui/core";

// Components
import PreplacedDefaultHeader from "../preplaced-default-header/preplaced-default-header.component";
import InterviewPreview from "../interview-preview/interview-preview.component";
import UserAvatar from "../user-avatar/user-avatar.component";
import PreviewCardsSkeleton from "../preview-cards-skeleton/preview-cards-skeleton.component";

// Stylesheets
import "./home.styles.scss";
import HomeViewModel from "./HomeViewModel";

const getSlicedArray = (arr, viewAll) => {
  if (viewAll === false) {
    return [...arr].reverse().slice(0, 4);
  } else {
    return [...arr].reverse();
  }
};

const Home = ({ userStore, editorStore, docStore }) => {
  const [viewAll, setViewAll] = useState({
    recentDocs: false,
    sharedDocs: false,
    ownedDocs: false,
  });
  const [viewModel, setViewModel] = useState({})
  const history = useHistory();

  useEffect(() => {
    const homeViewModel = new HomeViewModel({userStore, editorStore, docStore});
    setViewModel(homeViewModel);
  }, [])

  const createNewEditor = () => {
    const onSuccess = (hash) => {
      viewModel.redirectAndOpenModal(history, hash);
    };

    const onFailure = () => {
      alert("Sorry, something went wrong. Please try again!!");
    };

    // Deciding the docType, as of now by default it is "basic"
    const docType = "basic";

    // Generate a 6-digit passcode for the editor -> [Using randomatic lib. to generate the passcode]
    const passcode = randomatic("0", 6);

    // Create a object which holds all the editor creation information
    const newEditorObj = {
      passcode,
      docType,
      onSuccess,
      onFailure,
    };

    // Call the function which will create the editor's basic data on RT-DB
    viewModel.createNewDocAndRedirect(newEditorObj);
  };

  const toggleViewAll = (e) => {
    const { id } = e.target;

    setViewAll((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return useObserver(() => (
    <div className="home">
      <PreplacedDefaultHeader>
        <div className="home__headerSideBlock">
          <Button
            className="home__startInterviewButton"
            onClick={createNewEditor}
          >
            Start Interview
          </Button>

          <UserAvatar redirectAfterLogout="/login" />
        </div>
      </PreplacedDefaultHeader>

      <div className="home__previewsWrapper">
        {/* Recent Interviews */}
        <div className="home__previewsContainer">
          <div className="previewsContainer__header">
            <h1 className="previewsContainer__title">Recent Interviews Docs</h1>
            {viewModel.recentDocsLength > 4 && (
              <span
                className="previewsContainer__expandButton"
                id="recentDocs"
                onClick={toggleViewAll}
              >
                View all
              </span>
            )}
          </div>

          <div className="previewsContainer__previews">
            {viewModel.isDocsFetchLoading ? (
              <PreviewCardsSkeleton />
            ) : viewModel.recentDocsLength ? (
              getSlicedArray(
                viewModel.recentDocs,
                viewAll["recentDocs"]
              ).map((doc) => (
                <InterviewPreview key={`${doc}-recent`} doc={doc} />
              ))
            ) : (
              <div className="previewsContainer__noPreviews">
                No editors found!
              </div>
            )}
          </div>
        </div>

        {/* Your Interviews */}
        <div className="home__previewsContainer">
          <div className="previewsContainer__header">
            <h1 className="previewsContainer__title">Your Interview Docs</h1>
            {viewModel.ownedDocsLength > 4 && (
              <span
                className="previewsContainer__expandButton"
                id="ownedDocs"
                onClick={toggleViewAll}
              >
                View all
              </span>
            )}
          </div>

          <div className="previewsContainer__previews">
            {viewModel.isDocsFetchLoading ? (
              <PreviewCardsSkeleton />
            ) : viewModel.ownedDocsLength ? (
              getSlicedArray(
                viewModel.ownedDocs,
                viewAll["ownedDocs"]
              ).map((doc) => (
                <InterviewPreview key={`${doc}-owned`} doc={doc} />
              ))
            ) : (
              <div className="previewsContainer__noPreviews">
                No editors found!
              </div>
            )}
          </div>
        </div>

        {/* Shared Interviews Docs */}
        <div className="home__previewsContainer">
          <div className="previewsContainer__header">
            <h1 className="previewsContainer__title">Shared Interviews Docs</h1>
            {viewModel.sharedDocsLength > 4 && (
              <span
                className="previewsContainer__expandButton"
                id="sharedDocs"
                onClick={toggleViewAll}
              >
                View all
              </span>
            )}
          </div>

          <div className="previewsContainer__previews">
            {viewModel.isDocsFetchLoading ? (
              <PreviewCardsSkeleton />
            ) : viewModel.sharedDocsLength ? (
              getSlicedArray(
                viewModel.sharedDocs,
                viewAll["sharedDocs"]
              ).map((doc) => (
                <InterviewPreview key={`${doc}-shared`} doc={doc} />
              ))
            ) : (
              <div className="previewsContainer__noPreviews">
                No editors found!
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ));
};

export default compose(
  inject("userStore"),
  inject("docStore"),
  inject("editorStore")
)(Home);
