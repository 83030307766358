import React,{useEffect, useState} from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";
import "./tooltip.styles.scss"

// material ui icons
import CloseIcon from '@material-ui/icons/Close';

//ViewModel
import ToolTipViewModel from "./TooltipViewModel";

const Tooltip = ({walkthroughStore, docStore}) => {
    const [viewModel, setViewModel] = useState({});

    useEffect(() => {
      const tooltipViewModel = new ToolTipViewModel({walkthroughStore, docStore});
      setViewModel(tooltipViewModel);
    },[])
  
    return useObserver(() =>(
            <div className="tooltip_container tooltip_bottom" id="tooltip-element">
                <div className="tooltip_arrow"></div>
                <div className="tooltip">
                    <div className="tooltip_title">
                        <p>{viewModel.currentComponentHeading || ""}</p>
                        <span onClick={()=>viewModel.endWalkthrough(viewModel.componentIndex)}>
                            <CloseIcon />
                        </span>
                    </div>
                    <div className="tooltip_body">
                    {viewModel.currentComponentMessage || ""}
                    </div>
                    <div className="tooltip_buttons">
                        <button className="tooltip_next_btn"
                            onClick={viewModel.nextComponentWalkthrough}
                        >Next</button>
                        <button className="tooltip_skip_btn"
                            onClick={()=>viewModel.endWalkthrough(walkthroughStore.componentIndex)}
                        >Skip</button>
                    </div>
                </div>
            </div>
    ))
}

export default compose(inject("walkthroughStore"), inject("docStore"))(Tooltip);