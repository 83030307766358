import {action, computed} from "mobx"

class CodeEditorViewModel{
    constructor({editorStore}){
        this.editorStore = editorStore;
    } 

    @computed get currentSection(){
        return  this.editorStore.currentSection
    }
    @computed get language(){
        return  this.editorStore.language
    }
    @computed get editor(){
        return  this.editorStore.editor
    }

    @action setEditor = (codeMirror) => {
        this.editorStore.setEditor(codeMirror)
    }

    @action changeLanguageInDB = (value) => {
        this.editorStore.changeLanguageInDB(value)
    }
}

export default CodeEditorViewModel;