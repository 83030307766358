import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";

import NewLanding from "../new-landing/new-landing.component";
import Home from "../home/home.component";

//ViewModel
import RootLandingViewModel from './RootLandingViewModel'


const RootLanding = ({ userStore }) => {
  let accessTokenPresent = () => localStorage.getItem("accessToken");
  const [viewModel, setViewModel] = useState({});
  useEffect(() => {
    const rootLandingViewModel = new RootLandingViewModel({userStore})
    setViewModel(rootLandingViewModel)
  },[])
  return useObserver(() => (
    <React.Fragment>
      {viewModel.currentUser ? (
        <Home />
      ) : accessTokenPresent() ? (
        <Home />
      ) : (
        <NewLanding />
      )}
    </React.Fragment>
  ));
};

export default compose(inject("userStore"))(RootLanding);
