import DocStore from "./docStore";
import EditorStore from "./editorStore";
import UserStore from "./userStore";
import WalkthroughStore from "./walkthroughStore";

import { databaseRef, firebaseDB } from "../firebase/firebase";

class RootStore {
  constructor() {
    this.dbRef = databaseRef;
    this.firebaseDB = firebaseDB;
    this.docStore = new DocStore(this.dbRef, firebaseDB);
    this.editorStore = new EditorStore(this.dbRef, this.docStore);
    this.userStore = new UserStore(this.dbRef);
    this.walkthroughStore = new WalkthroughStore();
  }
}

export default RootStore;
