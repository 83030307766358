import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  createAccountWithEmailAndPassword,
  signInAccountWithEmailAndPassword,
  SignInWithGoogle,
  SignInWithGithub,
} from "../../firebase/firebase";

// Material-UI
import { Button, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./auth-card.styles.scss";
import EmailVerification from "../email-verification/email-verification.component";
const AuthCard = ({
  Login,
  hasCloseButton,
  isNavigationMessageEnabled,
  onAuthSuccess,
}) => {
  const [isLogin, setIsLogin] = useState(Login || false);
  const [isEmailVerify, setIsEmailVerify] = useState(false)
  const [userCredentials, setUserCredentials] = useState({
    username: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserCredentials((userCred) => ({
      ...userCred,
      [name]: value,
    }));
  };

  const goToLogin = () => {
    setIsEmailVerify(false)
    setIsLogin(true)
  }


  const toggleEmailVerify = () => {
    setIsEmailVerify(!isEmailVerify);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO: Add validations for all input fields

    if (isLogin) {
      signInAccountWithEmailAndPassword(userCredentials, onAuthSuccess);
    } else {
      createAccountWithEmailAndPassword(userCredentials, onAuthSuccess);
    }
  };

  return (
    <React.Fragment>
    {isEmailVerify ? 
    <EmailVerification goToLogin={goToLogin} toggleEmailVerify={toggleEmailVerify}></EmailVerification> : 
    <div className="authCard">
      {hasCloseButton && (
        <IconButton className="authCard__closeButton" onClick={onAuthSuccess}>
          <CloseIcon />
        </IconButton>
      )}

      <h1 className="authCard__heading">
        {isLogin ? "Login" : "Create Account"}
      </h1>

      <form onSubmit={handleSubmit}>
        {!isLogin && (
          <input
            type="text"
            name="username"
            value={userCredentials.username}
            onChange={handleChange}
            placeholder="Username"
            required
          />
        )}
        <input
          type="email"
          name="email"
          value={userCredentials.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <input
          type="password"
          name="password"
          value={userCredentials.password}
          onChange={handleChange}
          placeholder="Password"
          min="8"
          required
        />

        <Button type="submit" className="createAccountButton">
          {isLogin ? "Login" : "Create New Account"}
        </Button>

        {isLogin && (
            <Button className="forgotPasswordButton" onClick={toggleEmailVerify}>Forgot Password</Button>
        )}
      </form>
      <div className="otherMethodSignIn">
        <Button
          className="googleSignInButton"
          onClick={() => SignInWithGoogle(onAuthSuccess)}
        >
          <img
            src="https://img.icons8.com/fluent/48/000000/google-logo.png"
            alt="google-logo"
          />
          Google sign-in
        </Button>

        <Button
          className="githubSignInButton"
          onClick={() => SignInWithGithub(onAuthSuccess)}
        >
          <img
            src="https://img.icons8.com/color/48/000000/github-2.png"
            alt="github-logo"
          />
          Github sign-in
        </Button>
      </div>

      
      
      <div className="authCard__navigationMessage">
        {isNavigationMessageEnabled &&
          (isLogin ? (
            <span className="navigationMessage__content">
              Don't have an account yet!{" "}
              <Button onClick={() => setIsLogin(!isLogin)} className="nav__tab">
                Signup
              </Button>
            </span>
          ) : (
            <span>
              Already have an account!{" "}
              <Button onClick={() => setIsLogin(!isLogin)} className="nav__tab">
                Login
              </Button>
            </span>
          ))}
      </div>
    </div>}
    </React.Fragment>
  );
};

export default AuthCard;
