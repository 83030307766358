import {action} from "mobx"

class NewLandingViewModel{
    constructor({docStore, editorStore}){
        this.docStore = docStore;
        this.editorStore = editorStore;
    } 
    
    @action redirectAndOpenModal = (history, hash) => {
        this.editorStore.redirectAndOpenModal(history, hash);
    }

    @action createNewDocAndRedirect = (newEditorObj) => {
        this.docStore.createNewDocAndRedirect(newEditorObj);
    }
}

export default NewLandingViewModel 