import {action, autorun, computed, observable, reaction} from "mobx"

class SectionDropdownViewModel{
    @observable isOpen = false;

    constructor({docStore, editorStore, walkthroughStore}){
        this.docStore = docStore;
        this.editorStore = editorStore;
        this.walkthroughStore = walkthroughStore;
        window.SectionDropdownViewModel = this;
        // autorun(() => {
        //     if(this.editorStore.isAuthenticated){
        //         this.walkthroughStore.firstComponentWalkthrough()
        //     }
        // })
        // autorun(
        //   () =>  {
        //     const hash = window.location.hash.replace(/#/g, "");
    
        //     if (this.editorStore.currentSection && hash && this.dbRef) {
        //       this.docStore.dbRef
        //         .child(`${hash}/sections/${this.editorStore.currentSection}/name`)
        //         .on("value", (snapshot) => {
        //           const newSectionName = snapshot.val();
    
        //           if (newSectionName) {
        //             this.setCurrentSectionInput(newSectionName);
        //             // this.setCurrentSectionNewName(
        //             //   this.editorStore.currentSection,
        //             //   newSectionName
        //             // );
        //           } else {
        //             this.setCurrentSectionInput("");
        //           }
        //         });
        //     }
        //   }
        // );
    } 

    @computed get isAuthenticated() {
        return this.editorStore.isAuthenticated;
    }

    @computed get language() {
        return this.editorStore.language;
    }

    @computed get currentSection() {
        return this.editorStore.currentSection;
    }

    @computed get currentSectionInput(){
      return this.editorStore.currentSectionInput;
    }

    @computed get sections(){
        return this.docStore.sections ?  this.docStore.sections : {};
    }

    // @computed get currentSectionInput(){
    //     return this.editorStore.currentSectionInput;
    // }


    @action addNewInterviewSection = (onNewInterviewCreated) => {
      this.docStore.addNewInterviewSection(onNewInterviewCreated);
    }

    @action setCurrentSectionNewName = (currentSection, newName) => {
      this.docStore.setCurrentSectionNewName(currentSection, newName);
    }

    @action firstComponentWalkthrough = () => {
        this.walkthroughStore.firstComponentWalkthrough();
    }

    @action setCurrentSection = (id) => {
        this.editorStore.setCurrentSection(id);
    }

    @action setLanguage = (language) => {
        this.editorStore.setLanguage(language);
    }

    @action setCurrentSectionInput = (currentSection) => {
        this.editorStore.setCurrentSectionInput(currentSection);
    }
    

    @action updateSectionNameToDB = (newName, currentSection, onSuccess, onFailure) => {
        this.docStore.updateSectionNameToDB(newName, currentSection, onSuccess, onFailure);
    }

    @action removeInterviewSection = (sectionName, onInterviewSectionRemoved) => {
        this.docStore.removeInterviewSection(sectionName, onInterviewSectionRemoved);
    }

    @action checkNameClash = (newName) => {
        this.docStore.checkNameClash(newName);
    }

    @action handleChange = (e) => {
      this.editorStore.setCurrentSectionInput(e.target.value);
    };
  
    @action toggleDropdown = () => {
      this.isOpen = !this.isOpen;
    }
    
    @action saveName = () => {
        const newName = this.editorStore.currentSectionInput.trim();
        const lowerCasedName = newName.toLowerCase();
    
        // Returning if same name is entered
        if (
          lowerCasedName ===
          this.docStore.sections[this.editorStore.currentSection].name.toLowerCase()
        ) {
          return;
        }
    
        if (newName.length > 30) {
          alert("Length Exceeded!! Name should be less than 30 characters");
    
          this.editorStore.setCurrentSectionInput(
            this.docStore.sections[this.editorStore.currentSection]?.name ||
            this.docStore.sections[this.editorStore.currentSection]?.type ||
            this.editorStore.currentSection
          );
    
          return;
        }
    
        const onSuccess = (newName) => {
          this.editorStore.setCurrentSectionInput(newName);
          this.docStore.setCurrentSectionNewName(this.editorStore.currentSection, newName)       
        };
    
        const onFailure = () => {
          alert("Sorry something went wrong! Try again later");
        };
    
        const isValidName = () => {
          // Check for name clashes
    
          if (newName.length <= 0) return false;
          else if (this.docStore.checkNameClash(lowerCasedName)) return false;
          else return true;
        };
    
        // Save this to DB
        if (isValidName()) {
            this.docStore.updateSectionNameToDB(
              newName,
              this.editorStore.currentSection,              
              onSuccess,
              onFailure
          );
        } else {
          alert("Name already exists or is not valid!!");
    
          this.editorStore.setCurrentSectionInput(
            this.docStore.sections[this.editorStore.currentSection]?.name ||
            this.docStore.sections[this.editorStore.currentSection]?.type ||
            this.editorStore.currentSection
          );
        }
    };

    @action removeSelectedSection = (sectionName) => {
        const confirm = window.confirm(
          "Are you sure you want to delete this section?"
        );
        console.log(sectionName);
        if (confirm) {
          if (this.docStore.checkIfSectionCanBeDeleted(sectionName)) {
            console.log('here')
            this.docStore.removeInterviewSection(sectionName);
          } else alert("Cannot delete this section");
        }
    };

    @action addNewInterview = () => {
        const onNewInterviewCreated = (newSectionName) => {
            this.editorStore.setCurrentSection(newSectionName);
            this.isOpen = !this.isOpen;
        };
      
        this.docStore.addNewInterviewSection(onNewInterviewCreated);
    }
    
    @action changeCurrentSection = (id) => {
        if (!this.editorStore.currentSection.includes(id)) {
          this.editorStore.setCurrentSection(id);
          this.isOpen = !this.isOpen;
        }
    };

}

export default SectionDropdownViewModel;
