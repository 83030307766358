import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";
import html2canvas from "html2canvas";

// Material-UI
import { Button, IconButton, Drawer } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import DocumentNameEditor from "../document-name-editor/document-name-editor.component";
import InfoModal from "../info-modal/info-modal.component";
import SectionDropdown from "../section-dropdown/section-dropdown.component";
import AuthCard from "../auth-card/auth-card.component";
import UserAvatar from "../user-avatar/user-avatar.component";

// Stylesheets
import "./header.style.scss";
import HeaderViewModel from "./HeaderViewModel";

const Header = ({ editorStore, docStore, userStore, docId }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [modalState, setModalState] = useState({
    copy: editorStore.isShareInfoModalOpen || false,
    end: false,
  });
  const [uploadingThumbnail, setUploadingThumbnail] = useState(false);
  const [viewModel, setViewModel] = useState({})

  useEffect(() => {
    const headerViewModel = new HeaderViewModel({userStore, docStore, editorStore});
    setViewModel(headerViewModel);
  },[])

  const toggleCopyState = () => {
    setModalState((modalState) => ({
      ...modalState,
      copy: !modalState.copy,
    }));
  };

  const toggleEndState = () => {
    setModalState((modalState) => ({
      ...modalState,
      end: !modalState.end,
    }));
  };

  const toggleUploadingState = () => {
    setUploadingThumbnail((prev) => !prev);
  };

  const uploadAndEndInterview = () => {
    if (modalState.end === false) {
      setUploadingThumbnail(true);

      html2canvas(document.getElementsByClassName("editor__codeArea")[0], {
        scale: 0.15,
        backgroundColor: "#121212",
      }).then((canvas) => {
        canvas.toBlob((blob) => {
          viewModel.uploadDocThumbnailToFirebase(
            docId,
            blob,
            toggleEndState,
            toggleUploadingState
          );
        });
      });
    } else {
      setModalState((modal) => ({
        ...modal,
        end: !modal.end,
      }));
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const toggleLoginPopup = () => {
    setIsLoginPopupOpen(true);
  };

  const InviteButton = () => {
    return useObserver(() => (
      <Button
        className="header__copyButton"
        id="inviteButton"
        onClick={toggleCopyState}
      >
        <ShareIcon />
        Invite Others
      </Button>
    ));
  };

  const EndInterviewButton = () => {
    return useObserver(() => (
      <Button
        className="header__endButton"
        onClick={uploadAndEndInterview}
        id="endInterviewButton"
      >
        End Interview
      </Button>
    ));
  };

  const handleAuthSuccess = () => {
    setIsLoginPopupOpen(false);
  };

  return useObserver(() => (
    <div className="header">
      <DocumentNameEditor />

      {/* Hamburger Icon for smaller screens */}
      <IconButton className="header__hamIconButton" onClick={toggleMenu}>
        {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      {/* Section Dropdown */}
      <SectionDropdown />

      {/* Copy and End Buttons for larger screens */}
      <div className="header__buttons">
        <div>
          <InviteButton />
        </div>

        <div>
          <EndInterviewButton />
        </div>

        <div>
          {viewModel.currentUser ? (
            <UserAvatar />
          ) : (
            <Button className="header__loginButton" onClick={toggleLoginPopup}>
              Login/Register
            </Button>
          )}
        </div>
      </div>

      {/* Drawer Open in small screens */}
      <Drawer
        className="header__actionButtonsDrawer"
        anchor="top"
        open={isMenuOpen}
        onClose={toggleMenu}
      >
        <div className="actionButtonsDrawer__wrapper">
          <Button className="header__copyButton" onClick={toggleCopyState}>
            <ShareIcon />
            Invite Others
          </Button>

          <Button className="header__endButton" onClick={uploadAndEndInterview}>
            End Interview
          </Button>

          <div>
            {viewModel.currentUser ? (
              <UserAvatar />
            ) : (
              <Button
                className="header__loginButton"
                onClick={toggleLoginPopup}
              >
                Login/Register
              </Button>
            )}
          </div>
        </div>
      </Drawer>

      {/* Modal for copy link button*/}
      <InfoModal
        open={modalState.copy}
        onClose={toggleCopyState}
        isLoading={false}
        toggleCopyState={toggleCopyState}
      />

      {/* Modal for End interview button*/}
      <InfoModal
        open={modalState.end}
        onClose={toggleEndState}
        isEnd
        isLoading={uploadingThumbnail}
        toggleEndState={toggleEndState}
      />

      {/* Toggle login or signup modal */}
      {isLoginPopupOpen && (
        <div className="header__loginPopup">
          <AuthCard
            Login
            hasCloseButton={true}
            onAuthSuccess={handleAuthSuccess}
            isNavigationMessageEnabled={true}
          />
        </div>
      )}
    </div>
  ));
};

export default compose(
  inject("editorStore"),
  inject("docStore"),
  inject("userStore")
)(Header);
