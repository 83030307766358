import React, {useState, useEffect} from "react";
import { inject, useObserver } from "mobx-react";
import { compose } from "recompose";
import { useHistory } from "react-router-dom";
import randomatic from "randomatic";

import PreplacedDefaultHeader from "../preplaced-default-header/preplaced-default-header.component";
import Footer from "../footer/footer.component";

// Material-ui
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";

// Assets
import Editorpreview from "../../assets/editor-preview.svg";
import Google from "../../assets/logos/google-white.svg";
import Amazon from "../../assets/logos/amazon-white.svg";
import Microsoft from "../../assets/logos/microsoft-white.svg";
import Facebook from "../../assets/logos/facebook-white.svg";
import Cisco from "../../assets/logos/cisco-white.svg";
import Netflix from "../../assets/logos/netflix-white.svg";
import Uber from "../../assets/logos/uber-white.svg";
import Walmart from "../../assets/logos/walmart-white.svg";

// Stylesheets
import "./new-landing.styles.scss";

//ViewModel
import NewLandingViewModel from "./NewLandingViewModel";

const NewLanding = ({ docStore, editorStore }) => {
  const history = useHistory();
  const [viewModel, setViewModel] = useState({});

  useEffect(() => {
    const newLandingViewModel = new NewLandingViewModel({docStore, editorStore});
    setViewModel(newLandingViewModel);
  }, [])


  const createNewEditor = () => {
    const onSuccess = (hash) => {
      viewModel.redirectAndOpenModal(history, hash);
    };

    const onFailure = () => {
      alert("Sorry, something went wrong. Please try again!!");
    };

    // Deciding the docType, as of now by default it is "basic"
    const docType = "basic";

    // Generate a 6-digit passcode for the editor -> [Using randomatic lib. to generate the passcode]
    const passcode = randomatic("0", 6);

    // Create a object which holds all the editor creation information
    const newEditorObj = {
      passcode,
      docType,
      onSuccess,
      onFailure,
    };

    // Call the function which will create the editor's basic data on RT-DB
    viewModel.createNewDocAndRedirect(newEditorObj);
  };

  return useObserver(() => (
    <div className="landing">
      <PreplacedDefaultHeader />

      <div className="landing__heroText">
        <h1>A fresh take on conducting interviews</h1>
        <h3>
          An alternative to google docs built specifically for taking interviews
        </h3>

        <Button className="start-button" onClick={createNewEditor}>
          Start an interview &nbsp;
          <ArrowForwardIcon />
        </Button>
      </div>

      <div>
        <div className="preview_container">
          <img
            src={Editorpreview}
            className="editor-preview"
            alt="myinterview-doc"
          />
        </div>

        <div className="used_by">
          <p>Used By Interviewers At</p>
          <div className="companies">
            <div className="company_logo">
              <img src={Google} alt="google" />
            </div>
            <div className="company_logo">
              <img src={Amazon} alt="amazon" />
            </div>
            <div className="company_logo">
              <img src={Microsoft} className="microsoft" alt="microsoft" />
            </div>
            <div className="company_logo">
              <img src={Facebook} className="facebook" alt="facebook" />
            </div>
            <div className="company_logo">
              <img src={Cisco} alt="cisco" />
            </div>
            <div className="company_logo">
              <img src={Netflix} alt="netflix" />
            </div>
            <div className="company_logo">
              <img src={Uber} alt="uber" />
            </div>
            <div className="company_logo">
              <img src={Walmart} className="walmart" alt="walmart" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ));
};

export default compose(inject("docStore"), inject("editorStore"))(NewLanding);
