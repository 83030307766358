import {computed} from "mobx"


class LoginViewModel{
    constructor({userStore}){
        this.userStore = userStore;
    }
    
    @computed get currentUser(){
       return  this.userStore.currentUser;
    }
}

export default LoginViewModel;