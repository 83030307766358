import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";
import { useHistory } from "react-router-dom";

import AuthCard from "../auth-card/auth-card.component";

// Stylesheets
import "./login.styles.scss";
import LoginViewModel from "./LoginViewModel";

function Login({ userStore }) {
  const history = useHistory();
  const [viewModel, setViewModel] = useState({});

  useEffect(() => {
    const loginViewModel = new LoginViewModel({userStore});
    setViewModel(loginViewModel);
  },[])

  const handleAuthSuccess = () => {
    history.push("/");
  };

  return useObserver(
    () =>
      !viewModel.currentUser && (
        <div className="login">
          <AuthCard
            Login
            onAuthSuccess={handleAuthSuccess}
            isNavigationMessageEnabled={true}
          />
        </div>
      )
  );
}

export default compose(inject("userStore"))(Login);
