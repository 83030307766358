import dev from './dev.config.json'
import prod from './prod.config.json'

let config = {}
switch (window.location.hostname) {
  case 'preplaced.meetings.in':
    config = { ...prod }
    break
  default:
    config = { ...dev }
}

export default config
