import React from "react";

import PreplacedLogoIcon from "../../assets/preplaced-logo-svg.svg";

// Stylesheet
import "./footer.styles.scss";

const Footer = ({ ...otherProps }) => {
  return (
    <div className="footer" {...otherProps}>
      <div>
        <a href="https://www.preplaced.in/" target="_blank" rel="noreferrer">
          Book a Mock Interview
        </a>
        <a href="https://blog.preplaced.in/" target="_blank" rel="noreferrer">
          Blogs
        </a>
      </div>

      <div>
        A
        <img src={PreplacedLogoIcon} alt="Preplaced" />
        <a href="https://www.preplaced.in/" target="_blank" rel="noreferrer">
          Preplaced
        </a>
        Initiative
      </div>
    </div>
  );
};

export default Footer;
