import {action} from "mobx"

class PasswordCheckViewModel{
    constructor({docStore, editorStore}){
        this.docStore = docStore;
        this.editorStore = editorStore;
    } 

    @action setIsAuthenticated = (value) => {
        this.editorStore.setIsAuthenticated(value)
    }

    @action validateDocPassword = (hash,passcode,passwordValidated,invalidPasswordEntered) => 
        this.docStore.validateDocPassword(hash,passcode,passwordValidated,invalidPasswordEntered);


    @action createNewDocAndRedirect = (hash,passcode,passwordValidated,invalidPasswordEntered) => {
        this.docStore.validateDocPassword(hash,passcode,passwordValidated,invalidPasswordEntered);
    }
}

export default PasswordCheckViewModel;