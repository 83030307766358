import React from "react";

// Material-UI
import { Skeleton } from "@material-ui/lab";

// Stylesheet
import "./preview-cards-skeleton.styles.scss";

const PreviewCardsSkeleton = () => {
  return (
    <React.Fragment>
      {[...Array(4).keys()].map((_, idx) => (
        <div key={`preview__${idx}`} className="previewCardSkeleton">
          <Skeleton width={260} height={250} />

          <div className="previewCardSkeleton__bottom">
            <Skeleton width={260} height={80} />
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default PreviewCardsSkeleton;
