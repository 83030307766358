import React,{useEffect, useState} from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";
import { useHistory } from "react-router-dom";

import AuthCard from "../auth-card/auth-card.component";

// Stylesheets
import "./sign-up.styles.scss";
import SignupViewModel from "./SignUpViewModel";

const SignUp = ({ userStore }) => {
  const history = useHistory();
  const [viewModel, setViewModel] = useState({});

  useEffect(() => {
    const signupViewModel = new SignupViewModel({userStore});
    setViewModel(signupViewModel);
  },[])

  const handleAuthSuccess = () => {
    history.push("/");
  };

  return useObserver(
    () =>
      !viewModel.currentUser && (
        <div className="signup">
          <AuthCard
                onAuthSuccess={handleAuthSuccess}
                isNavigationMessageEnabled={true}
            />
        </div>
      )
  );
};

export default compose(inject("userStore"))(SignUp);
