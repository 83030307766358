import React, { useState, useEffect, useRef } from "react";

// Material-UI
import { Button, IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

// Stylesheet
import "./card.styles.css";

const Card = (props) => {
  const { title, message, isNew, onSubmitButtonClick } = props.cardProps;

  const [value, setValue] = useState("");
  const [isHidden, setisHidden] = useState(true);
  const [docType, setDocType] = useState(false);

  const passwordFieldRef = useRef(null);

  // Autofocus the password field
  useEffect(() => {
    if (passwordFieldRef) {
      passwordFieldRef.current.focus();
    }
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleDocTypeChange = (e) => {
    setDocType((docType) => !docType);
  };

  const toggleHiddenState = () => {
    setisHidden((isHidden) => !isHidden);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const passcode = value.trim();
    const formData = isNew
      ? {
          docType: docType ? "package" : "basic",
          passcode,
        }
      : {
          passcode,
        };

    onSubmitButtonClick(formData);
  };

  return (
    <form className="card__form" onSubmit={handleSubmit}>
      <h1 className="card__formTitle">{title}</h1>

      <span className="card__message">{message}</span>

      {isNew && (
        <div className="card__toggleContainer">
          <span className="card__sectionHeading">Document Type</span>

          <span className="card__toggleSliderContainer">
            <span className={`${!docType && "card__togglePackageHighlight"}`}>
              Basic
            </span>

            <input
              type="checkbox"
              id="docType"
              checked={docType}
              onChange={handleDocTypeChange}
              className="card__toggleCheckbox"
            />
            <label htmlFor="docType" className="card__toggleLabel"></label>

            <span className={`${docType && "card__togglePackageHighlight"}`}>
              Package
            </span>
          </span>

          <div className="card__toggleDocTypeInfo">
            {!docType ? (
              <span>❕ Includes only interview slot</span>
            ) : (
              <React.Fragment>
                <span>Goal Setting - 1 slot</span>
                <span>Interviews - 3 slots (default)</span>
                <span>Feedback - 1 slot</span>
              </React.Fragment>
            )}
          </div>
        </div>
      )}

      <div className="card__inputContainer">
        <span className="card__sectionHeading">Passcode</span>
        <input
          type={isHidden ? "password" : "text"}
          className="card__input"
          value={value}
          onChange={handleChange}
          placeholder="Enter passcode here"
          maxLength="10"
          ref={passwordFieldRef}
          required
        />

        <IconButton className="card__toggleHide" onClick={toggleHiddenState}>
          {isHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </div>

      <Button className="card__submit" type="submit">
        Enter
      </Button>
    </form>
  );
};

export default Card;
