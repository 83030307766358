import {action, computed} from "mobx"

class ToolTipViewModel{
    constructor({walkthroughStore, docStore}){
        this.walkthroughStore = walkthroughStore;
        this.docStore = docStore;
        this.walkthroughStore.firstComponentWalkthrough();
    } 

    @computed get currentComponentHeading(){
        return this.walkthroughStore.currentComponentData.heading;
    }

    @computed get currentComponentMessage(){
        return this.walkthroughStore.currentComponentData.message;
    }

    @computed get componentIndex(){
        console.log(this.walkthroughStore.componentIndex)
        return this.walkthroughStore.componentIndex;
    }

    @action endWalkthrough = (componentIndex) => {
        this.walkthroughStore.endWalkthrough(componentIndex);
    }

    @action nextComponentWalkthrough = () => {
        this.walkthroughStore.nextComponentWalkthrough();
    }
}

export default ToolTipViewModel 