import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";

// Components
import EditorCardsPreview from "../editor-cards-preview/editor-cards-preview.component";
import Editor from "../editor/editor.component";
import PasswordCheck from "../password-check/password-check.component";
import PageNotFound from "../page-not-found/page-not-found.component";

//ViewModel
import PrefetchEditorViewModel from "./PrefetchEditorViewModel";


const PrefetchEditor = ({ editorStore, docStore }) => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [isDocAvailable, setIsDocAvailable] = useState(false);
  const [viewModel, setViewModel] = useState({});

  useEffect(() => {
    console.log(docStore)
    const prefetchEditorViewModel = new PrefetchEditorViewModel({editorStore, docStore});
    setViewModel(prefetchEditorViewModel);
  }, [])

  // useEffect(() => {
  //   const hash = window.location.hash.replace(/#/g, "");

  //   const onFailure = () => {
  //     setIsLoading(false);
  //     setIsDocAvailable(false);
  //   };

  //   const onOwnerFailure = () => {
  //     setIsLoading(false);
  //     setIsDocAvailable(true);
  //   };

  //   const onOwnerSuccess = () => {
  //     setIsLoading(false);
  //     setIsDocAvailable(true);

  //     editorStore.setIsAuthenticated(true);
  //   };

  //   const onSuccess = () => {
  //     docStore.checkIfUserOwnsTheDoc(hash, onOwnerSuccess, onOwnerFailure);
  //   };

  //   if (hash) {
  //     docStore.checkIfDocExists(hash, onSuccess, onFailure);
  //   } else {
  //     onFailure();
  //   }
  // }, [docStore, editorStore]);

  return useObserver(() =>
   viewModel.isLoading ? (
      <EditorCardsPreview />
    ) : viewModel.isDocAvailable ? (
      viewModel.isAuthenticated ? (
        <Editor/>
      ) : (
        <PasswordCheck />
      )
    ) : (
      <PageNotFound />
    )
  );
};

export default compose(
  inject("editorStore"),
  inject("docStore")
)(PrefetchEditor);
