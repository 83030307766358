import { useEffect } from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";
import { Switch, Route } from "react-router-dom";

// Firebase
import { auth } from "./firebase/firebase";

// Font
import "@vetixy/circular-std";

// Container
import ProtectedRoute from "./higher-order-components/protected-route/protected-route.container";

// Components
import RootLanding from "./components/root-landing/root-landing.component";
import Login from "./components/login/login.component";
import SignUp from "./components/sign-up/sign-up.component";
import EmailVerification from './components/email-verification/email-verification.component'
import ResetPassword from "./components/reset-password/reset-password.component";
import PageNotFound from "./components/page-not-found/page-not-found.component";
import PrefetchEditor from "./components/prefetch-editor/prefetch-editor.component";

// Stylesheets
import "./App.scss";

function App(props) {
  const { userStore } = props;

  // Auth State Changed observer
  useEffect(() => {
    const subscription = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        const { displayName, email, photoURL } = userAuth;

        userStore.setCurrentUser({
          displayName,
          email,
          photoURL,
        });

        // userStore.addSnapshotObserver();

        localStorage.setItem("accessToken", userAuth.uid);
      } else {
        localStorage.removeItem("accessToken");
        userStore.setCurrentUser(null);
      }
    });

    return () => {
      subscription();
      userStore.removeSnapshotObserver();
    };
  }, [userStore]);

  return useObserver(() => (
    <div className="app">
      <Switch>
        <Route path="/editor" component={PrefetchEditor} />
        <ProtectedRoute exact path="/login" redirectTo="/" component={Login} />
        <ProtectedRoute
          exact
          path="/signup"
          redirectTo="/"
          component={SignUp}
        />
        <Route exact path="/email-verification" component={EmailVerification} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/" component={RootLanding} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  ));
}

export default compose(inject("userStore"))(App);
