import {action, computed} from "mobx"

class HeaderViewModel{
    constructor({userStore, docStore, editorStore}){
        this.userStore = userStore;
        this.docStore = docStore;
        this.editorStore = editorStore;
    } 

    @computed get currentUser(){
        return  this.userStore.currentUser
    }

    @computed get isShareInfoModalOpen(){
        return  this.editorStore.isShareInfoModalOpen
    }

    @action uploadDocThumbnailToFirebase = (docId,file,onThumbnailUploadSuccess,loadingAnimation) => {
        this.docStore.uploadDocThumbnailToFirebase(docId,file,onThumbnailUploadSuccess,loadingAnimation);
    }
}

export default HeaderViewModel