export const BASIC_DOCTYPE_SECTION_STRUCTURE = {
  section_1: {
    type: "interview",
    name: "interview 1",
    language: "text/plain",
    data: {},
  },
};

export const PACKAGE_DOCTYPE_SECTION_STRUCTURE = {
  section_1: {
    type: "goal-setting",
    name: "goal setting",
    data: {},
  },
  section_2: {
    type: "feedback",
    data: {},
  },
  section_3: {
    type: "interview",
    name: "interview 1",
    language: "text/plain",
    data: {},
  },
  section_4: {
    type: "interview",
    name: "interview 2",
    language: "text/plain",
    data: {},
  },
  section_5: {
    type: "interview",
    name: "interview 3",
    language: "text/plain",
    data: {},
  },
};

export function ordinal_suffix_of(i) {
  var j = i % 10,
      k = i % 100;
  if (j == 1 && k != 11) {
      return i + "st";
  }
  if (j == 2 && k != 12) {
      return i + "nd";
  }
  if (j == 3 && k != 13) {
      return i + "rd";
  }
  return i + "th";
}

