import React, { useEffect, useState } from "react";
import { inject, useObserver } from "mobx-react";
import { compose } from "recompose";

// Material-UI
import { Avatar, MenuItem, ListItemIcon } from "@material-ui/core";
// import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// Firebase
import { auth } from "../../firebase/firebase";

// Stylesheet
import "./user-avatar.styles.scss";

//ViewModel
import UserAvatarViewModel from "./UserAvatarViewModel";

const UserAvatar = ({ userStore, redirectAfterLogout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [viewModel, setViewModel] = useState({});

  useEffect(() => {
    const userAvatarViewModel = new UserAvatarViewModel({userStore});
    setViewModel(userAvatarViewModel);
  },[])

  const toggleDropdown = () => {
    setIsDropdownOpen((val) => !val);
  };

  const signOut = () => {
    auth.signOut();
  };

  return useObserver(() => (
    <div className="userAvatar__avatarContainer">
      <span className="avatarContainer__avatar" onClick={toggleDropdown}>
        <Avatar src={viewModel?.currentUser?.photoURL} />
      </span>

      <div
        className={`userAvatar__userOptionsDropdown ${
          isDropdownOpen && "userAvatar__userOptionsDropdown--open"
        }`}
      >
        <MenuItem className="userOptionsDropdown__logout" onClick={signOut}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </div>
    </div>
  ));
};

export default compose(inject("userStore"))(UserAvatar);
