import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { inject } from "mobx-react";

import Card from "../card/card.component";

// Stylesheets
import "./password-check.styles.scss";
import PasswordCheckViewModel from "./PasswordCheckViewModel";

const PasswordCheck = (props) => {
  let { editorStore, docStore } = props;

  const[viewModel, setViewModel] = useState({})

  useEffect(() => {
    const passwordCheckViewModel = new PasswordCheckViewModel({editorStore,docStore});
    setViewModel(passwordCheckViewModel);
  },[])

  const passwordValidated = () => {
    viewModel.setIsAuthenticated(true);
  };

  const invalidPasswordEntered = () => {
    alert("The password is incorrect!");
  };

  const validatePassword = ({ passcode }) => {
    const hash = window.location.hash.replace(/#/g, "");
    if (hash && docStore && passcode.length > 0) {
      viewModel.validateDocPassword(
        hash,
        passcode,
        passwordValidated,
        invalidPasswordEntered
      );
    } else {
      alert("Password incorrect!!!");
    }
  };

  return (
    <div className="passwordCheck">
      <Card
        cardProps={{
          title: "Verification",
          message: "Type your passcode here to open the editor",
          onSubmitButtonClick: validatePassword,
        }}
      />
    </div>
  );
};

export default compose(
  inject("editorStore"),
  inject("docStore")
)(PasswordCheck);
