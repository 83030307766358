import {action,computed, autorun, reaction} from 'mobx';

class EditorViewModel {

    constructor({editorStore, docStore, walkthroughStore}){
        this.editorStore = editorStore;
        this.docStore = docStore;
        this.walkthroughStore = walkthroughStore;
        autorun(() => {
            if (this.editorStore.isAuthenticated) {
                const hash = window.location.hash.replace(/#/g, "");
          
                const setInitialSectionAfterFetch = (sectionKey) => {
                  this.editorStore.setCurrentSection(sectionKey)
                };
          
                if (hash && this.docStore.dbRef) {
                this.docStore.dbRef
                    .child(hash)
                    .once("value")
                    .then((snapshot) => {
                      snapshot.forEach((data) => {
                        const key = data.key.toLocaleLowerCase();
                        // console.log("Key: ", key);
                        if (key === "doctype") this.docStore.setDocumentType(data.key);
          
                        if (key === "passcode") this.docStore.setPasscode(data.val());
          
                        if (key === "docname") this.docStore.setDocumentName(data.val());
          
                        if (key === "sections")
                          this.docStore.setSections(data, true, setInitialSectionAfterFetch);
                      });
                    });
                }
              }
        })      
        autorun(() => {
            const hash = window.location.hash.replace(/#/g, "");
      
            const sectionsRef = this.docStore.dbRef.child(`${hash}/sections/`);
        
            const onInterviewSectionRemoved = (newSection) => {
              this.editorStore.setCurrentSection(newSection);
            };
        
            // Adding a event listener to the sections ref to listen for new section addition changes
            sectionsRef.on("child_added", (data) => {
              this.docStore.updateSectionsAfterUpdatingDB("update", data);
            });
        
            // Adding a event listener to the sections ref to listen for section deletion changes
            sectionsRef.on("child_removed", (data) => {
              this.docStore.updateSectionsAfterUpdatingDB(
                "delete",
                data,
                this.editorStore.currentSection,
                onInterviewSectionRemoved
              );
            });  
        })
    }

    @computed get currentSection() {
        return this.editorStore.currentSection
    }

    @computed get isIframe() {
      return this.docStore.isIframe;
    }

    @computed get showWalkthrough(){
      return !this.isIframe && !localStorage.getItem("walkthrough")
    }

    @action setIsAuthenticated = (bool) => {
        this.editorStore.setIsAuthenticated(bool);
    }

    @action setEditor = (value) => {
        this.editorStore.setEditor(value);
    }

    @action setCurrentSection = (value) => {
        this.editorStore.setCurrentSection(value);;
    }
    
    @action setSectionsToEmpty = () => {
        this.docStore.setSectionsToEmpty();
    }

    @action setCurrentSection = (sectionKey) => {
        this.editorStore.setCurrentSection(sectionKey);
    }

    @action setSections = (data, bool, setInitialSectionAfterFetch) => {
        this.docStore.setSections(data, bool, setInitialSectionAfterFetch);
    }

    @action updateSectionsAfterUpdatingDB = (action,data,currentSection,onInterviewSectionRemoved) => {
        this.docStore.updateSectionsAfterUpdatingDB(action,data,currentSection,onInterviewSectionRemoved)
    }

    @action setDocumentType = (key) => {
        this.docStore.setDocumentType(key);
    }

    @action setPasscode = (value) => {
        this.docStore.setPasscode(value);
    }

    @action setDocumentName = (value) => {
        this.docStore.setDocumentName(value);
    }

}


export default EditorViewModel;