import { action,autorun, observable, reaction } from "mobx";

class EditorStore {
  @observable isAuthenticated = true; // initialized as true to skip the passcode check
  @observable language = "text/plain";
  @observable theme = "material";
  @observable editor = null;
  @observable currentSection = null;
  @observable previousSection = null;
  @observable isShareInfoModalOpen = false;
  @observable currentSectionInput = "";

  constructor(dbRef, docStoreRef) {
    this.dbRef = dbRef;
    this.docStoreRef = docStoreRef;

    // Reaction for language change which changes the mode in editor
    reaction(
      () => this.language,
      (languageValue) => {
        if (languageValue) {
          this.editor.setOption("mode", `${languageValue}`);
        }
      }
    );

    // Reaction for language listener, name listener "off" event
    reaction(
      () => this.previousSection,
      (updatedValue) => {
        const hash = window.location.hash.replace(/#/g, "");

        if (updatedValue && updatedValue !== this.currentSection) {
          if (hash && this.dbRef) {
            this.dbRef.child(`${hash}/sections/${updatedValue}/language`).off();
            this.dbRef.child(`${hash}/sections/${updatedValue}/name`).off();
          }
        }
      }
    );

    // Reaction for theme change
    reaction(
      () => this.theme,
      (themeValue) => {
        this.editor.setOption("theme", themeValue);
      }
    );

    // Reaction for name listener "on" event, which executes whenever section changes
    
  }

  redirectAndOpenModal(history, hash) {
    history.push(`/editor/#${hash}`);

    this.isShareInfoModalOpen = true;
  }

  changeLanguageInDB(language) {
    const hash = window.location.hash.replace(/#/g, "");
    const ref = this.dbRef;

    if (hash && ref) {
      ref
        .child(`${hash}/sections/${this.currentSection}/`)
        .update({ language })
        .then(() => {
          this.setLanguage(language);
        })
        .catch((err) => console.log("Failed to change the language!"));
    }
  }

  @action setIsAuthenticated = (authenticated) => {
    this.isAuthenticated = authenticated;
  };

  @action setLanguage = (language) => {
    this.language = language;
  };

  @action setTheme = (theme) => {
    this.theme = theme;
  };

  @action setEditor = (editor) => {
    this.editor = editor;
  };

  @action setPreviousSection = (prevSection) => {
    this.previousSection = prevSection;
  };

  @action setCurrentSection = (newSection) => {
    console.log('at SetCurrentSection')
    this.setPreviousSection(this.currentSection);
    this.currentSection = newSection;
    this.getCurrentSectionNameAndLanguage();
  };

  getCurrentSectionNameAndLanguage = () => {
    const hash = window.location.hash.replace(/#/g, "");
    this.dbRef
      .child(`${hash}/sections/${this.currentSection}`)
      .on("value", (snapshot) => {
        const newSectionName = snapshot.val().name;
        if (newSectionName) {
          this.setCurrentSectionInput(newSectionName);
        }
        else{
          this.setCurrentSectionInput("Interview Section");
        }
        const newLanguage = snapshot.val().language;
        if (newLanguage) {
          this.setLanguage(newLanguage);
        } else {
          this.setLanguage("text/plain");
        }
      });
  }

  @action setCurrentSectionInput = (value) => {
    this.currentSectionInput = value;
  };
}

export default EditorStore;
