import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { Provider as MobxProvider }  from 'mobx-react';
import { BrowserRouter } from "react-router-dom";

import store from "./redux/store";
import RootStore from './stores/rootStore';

import App from "./App";

import "./index.css";
let mobxStore = new RootStore();

window.mobxStore = mobxStore;

ReactDOM.render(
  <Provider store={store}>
    <MobxProvider {...mobxStore} >
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </MobxProvider>
  </Provider>,
  document.getElementById("root")
);
