import { action, autorun, computed, observable, reaction } from "mobx";

import firebase from "firebase/app";
import {
  auth,
  addDocIdInOwnedDocs,
  storeDocIdLocally,
  uploadDocThumbnail,
  checkIfLoggedInUserOwnsTheDoc,
} from "../firebase/firebase";

import {
  BASIC_DOCTYPE_SECTION_STRUCTURE,
  PACKAGE_DOCTYPE_SECTION_STRUCTURE,
  ordinal_suffix_of
} from "../utils/utils";
import preplacedDefaultHeaderComponent from "../components/preplaced-default-header/preplaced-default-header.component";

class DocStore {
  @observable docIds = [];
  @observable documentType = null;
  @observable interviews = [];
  @observable sections = {};
  @observable passcode = null;
  @observable documentName = "";
  @observable documentNameInput = "";
  @observable isIframe= false;

  constructor(dbRef, firebaseDB) {
    this.dbRef = dbRef;
    this.firebaseDB = firebaseDB;
    this.setIframeVariables();

    // Reaction for input in document name component
    autorun(() => {
      const hash = window.location.hash.replace(/#/g, "");
      if (hash && this.dbRef) {
        this.dbRef.child(`${hash}/docName`).on("value", (snapshot) => {
          const newDocName = snapshot.val();

          if (newDocName && newDocName !== this.documentName) {
            this.documentName = newDocName;
            this.documentNameInput = newDocName;
          }
        });
      }

      return () => {
        if (hash && this.dbRef) {
          this.dbRef.child(`${hash}/docName`).off();
        }
      }
    })

    reaction(
      () => this.documentName,
      (newName) => {
        if (newName) this.setDocumentNameInput(newName);
      }
    );
  }

  @action setIframeVariables(){
    if(document.location.ancestorOrigins.length > 0){
      this.isIframe = true;
    }
  }

  checkIfDocExists = (hash, onSuccess, onFailure) => {
    // const ref = this.dbRef;
    const ref = this.firebaseDB.ref(hash);
    ref
      .once("value")
      .then((snapshot) => {
        snapshot.exists() ? onSuccess(snapshot.val()) : onFailure();
      })
      .catch((e) => onFailure());
  };

  checkIfUserOwnsTheDoc = (
    hash,
    onSuccessAuthenticate,
    onFailureAuthenticate
  ) => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      checkIfLoggedInUserOwnsTheDoc(
        hash,
        currentUser,
        onSuccessAuthenticate,
        onFailureAuthenticate
      );
    } else {
      storeDocIdLocally(hash, onSuccessAuthenticate, onFailureAuthenticate);
    }
  };

  createSectionData = (goalSettingCount, consultingSessionCount, mockInterviewCount) =>{
    let sectionData = {};
    let count = 1;
    for (let i = 0; i < goalSettingCount; i++){
      let prefix = goalSettingCount > 1 ? `${ordinal_suffix_of(i + 1)} `: '';
      sectionData[`section_${count++}`] = {
        type: "goal-setting",
        name: `${prefix}Goal Setting`,
        language: "text/plain",
        data: {},
      }
    }
    for (let i = 0; i < consultingSessionCount; i++){
      let prefix = consultingSessionCount > 1 ? `${ordinal_suffix_of(i + 1)} `: '';
      sectionData[`section_${count++}`] = {
        type: "consulting-session",
        name: `${prefix}Consulting Session`,
        language: "text/plain",
        data: {},
      }
    }
    for (let i = 0; i < mockInterviewCount; i++){
      let prefix = mockInterviewCount > 1 ? `${ordinal_suffix_of(i + 1)} `: '';
      sectionData[`section_${count++}`] = {
        type: "interview",
        name: `${prefix}Mock Interview`,
        language: "text/plain",
        data: {},
      }
    }
    console.error(sectionData);
    return sectionData;
  };

  createNewDocWithPredefinedValues = (params, onSuccess, onFailure) => {
    let { hash, passcode, name ,goalSettingCount, consultingSessionCount, 
      mockInterviewCount } = params;
    const ref = this.firebaseDB.ref(hash);
    let totalSectionsCount = goalSettingCount + consultingSessionCount + mockInterviewCount;
    let sectionsData = this.createSectionData(goalSettingCount, consultingSessionCount, mockInterviewCount);
    ref
    .set({
      docName: name,
      passcode,
      docType: "basic",
      sectionCountFlag: totalSectionsCount,
      interviewCountFlag: mockInterviewCount,
      owner: 'meetings@preplaced.co.in',
      createdAt: firebase.firestore.Timestamp.now(),
      sharedWith: [],
      sections: sectionsData,
    })
    .then(() => {
      console.log("doc created");
      onSuccess && onSuccess(ref.key);
    })
    .catch((err) => {
      onFailure && onFailure();
    });

  }


  createNewDocAndRedirect = (newEditorData) => {
    const { passcode, docType, onSuccess, onFailure } = newEditorData;
    const ref = this.dbRef.push();

    const sectionsData =
      docType === "basic"
        ? BASIC_DOCTYPE_SECTION_STRUCTURE
        : PACKAGE_DOCTYPE_SECTION_STRUCTURE;

    const userAuth = auth.currentUser;

    if (userAuth) {
      ref
        .set({
          docName: "untitled",
          passcode,
          docType,
          sectionCountFlag: docType === "basic" ? 1 : 5,
          interviewCountFlag: docType === "basic" ? 1 : 3,
          owner: userAuth.email,
          createdAt: firebase.firestore.Timestamp.now(),
          sharedWith: [],
          sections: sectionsData,
        })
        .then(() => {
          addDocIdInOwnedDocs(ref.key, userAuth, onSuccess, onSuccess);
        })
        .catch((err) => {
          onFailure();
        });
    } else {
      ref
        .set({
          docName: "untitled",
          passcode,
          docType,
          sectionCountFlag: docType === "basic" ? 1 : 5,
          interviewCountFlag: docType === "basic" ? 1 : 3,
          owner: "unknown",
          createdAt: firebase.firestore.Timestamp.now(),
          sharedWith: [],
          sections: sectionsData,
        })
        .then(() => {
          let ownedDocsLocal =
            JSON.parse(window.localStorage.getItem("ownedDocs")) || [];
          let recentDocsLocal =
            JSON.parse(window.localStorage.getItem("recentDocs")) || [];

          // Storing Owned docs locally
          if (!ownedDocsLocal.includes(ref.key)) {
            ownedDocsLocal.push(`${ref.key}`);

            window.localStorage.setItem(
              "ownedDocs",
              JSON.stringify(ownedDocsLocal)
            );
          }

          // Fltering & Storing Recent docs locally
          if (recentDocsLocal.includes(ref.key)) {
            recentDocsLocal.filter((doc) => doc !== ref.key);
          }

          recentDocsLocal.push(`${ref.key}`);

          window.localStorage.setItem(
            "recentDocs",
            JSON.stringify(recentDocsLocal)
          );

          onSuccess(ref.key);
        })
        .catch((err) => {
          onFailure();
        });
    }
  };

  validateDocPassword = (doc, passcode, successCallback, errorCallback) => {
    this.dbRef
      .child(doc)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val().passcode === passcode) {
          successCallback();
        } else {
          errorCallback();
        }
      });
  };

  getFullDocData = (child, onSuccess) => {
    this.dbRef
      .child(child)
      .once("value")
      .then((data) => {
        const purifiedData = {};

        data.forEach((value) => {
          if (value.key === "createdAt") {
            const firebaseDateObj = value.val();
            const timestamp = new firebase.firestore.Timestamp(
              firebaseDateObj.seconds,
              firebaseDateObj.nanoseconds
            ).toDate();

            purifiedData[value.key] = timestamp;
          } else {
            purifiedData[value.key] = value.val();
          }
        });

        onSuccess(purifiedData);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  checkIfSectionCanBeDeleted = (sectionName) => {
    const sectionList = Object.keys(this.sections);
    console.log('Here')
    const checkInterviewsLength = sectionList.filter(
      (section) => this.sections[section].type === "interview"
    ).length;
    console.log(checkInterviewsLength);
    return this.sections[sectionName].type === "interview" &&
      checkInterviewsLength > 1
      ? true
      : false;
  };

  checkNameClash = (name) => {
    return Object.keys(this.sections).find(
      (section) =>
        this.sections[section].name.toLowerCase() === name ||
        this.sections[section].type.toLowerCase() === name ||
        section.toLowerCase() === name
    );
  };

  @action setSectionsToEmpty = () => {
    this.sections = {};
  };

  @action setDocIds(docIds) {
    this.docIds = docIds;
  }

  @action setDocumentType(type) {
    this.documentType = type;
  }

  @action setPasscode(passcode) {
    this.passcode = passcode;
  }

  @action setDocumentName(documentName) {
    this.documentName = documentName;
  }

  @action setDocumentNameInput(documentNameInput) {
    this.documentNameInput = documentNameInput;
  }

  @action setCurrentSectionNewName(currentSection, newName) {
    this.sections[currentSection].name = newName;
  }

  @action setSections(sections, setInitialSection, onSuccess) {
    const sectionsList = {};

    sections.forEach((section) => {
      sectionsList[section.key] = {
        type: section.val().type,
        name: section.val().name || null,
        title: section.key,
        language: section.val().language || "text/plain",
      };
    });

    this.sections = sectionsList;
    console.log("sections: ", sectionsList);

    if (setInitialSection) {
      const sectionsArray = Object.keys(sectionsList);

      const sectionKey = sectionsArray[0];

      onSuccess(sectionKey);
    }
  }

  @action setInterviews = (interviews) => {
    this.interviews = interviews;
  };

  getCountersFromDB = async (hash) => {
    let sectionCount, interviewCount;

    // Get the section and interview counts from DB
    await this.dbRef
      .child(hash)
      .once("value")
      .then((snapshot) => {
        sectionCount = +snapshot.val().sectionCountFlag + 1;
        interviewCount = +snapshot.val().interviewCountFlag + 1;
      });

    // Update the count values
    await this.dbRef.child(`${hash}/`).update({
      sectionCountFlag: sectionCount,
      interviewCountFlag: interviewCount,
    });

    return { sectionCount, interviewCount };
  };

  @action addNewInterviewSection = async (onSuccess) => {
    const hash = window.location.hash.replace(/#/g, "");
    const ref = this.dbRef;

    const { sectionCount, interviewCount } = await this.getCountersFromDB(hash);

    // Now add the new section to the DB
    ref
      .child(`${hash}/sections/section_${sectionCount}/`)
      .set({
        type: "interview",
        name: `interview ${interviewCount}`,
        language: "text/plain",
        data: {},
      })
      .then(() => {
        onSuccess(`section_${sectionCount}`, `interview ${interviewCount}`);
      })
      .catch((err) => console.log("Failed creating a new interview section!"));
  };

  @action updateSectionsAfterUpdatingDB = (
    type,
    data,
    currentSection,
    onDelete
  ) => {
    if (type === "update") {
      const obj = {
        title: data.key,
        type: data.val().type,
        name: data.val().name,
        language: data.val().language || "text/plain",
      };

      this.sections[data.key] = obj;

      // One section was removed
    } else if (type === "delete") {
      delete this.sections[data.key];

      // This means the current section which the user is working is removed, so we need to update the values on screen
      if (currentSection === data.key) {
        const { lastSection } = this.findTheLastInterviewAndName();

        onDelete(lastSection);
      }
    }
  };

  @action updateSectionNameToDB = (
    newName,
    currentSection,
    onSuccess,
    onFailure
  ) => {
    const hash = window.location.hash.replace(/#/g, "");
    const ref = this.dbRef;

    ref
      .child(`${hash}/sections/${currentSection}/`)
      .update({
        name: newName,
      })
      .then(() => {
        onSuccess(newName);
      })
      .catch((err) => onFailure());
  };

  @action addNewInterview = (interview) => {
    let newInterviews = [...this.interviews, interview];

    this.setInterviews(newInterviews);
  };

  findTheLastInterviewAndName = () => {
    const sectionList = Object.keys(this.sections);

    const lastSection = sectionList
      .filter((section) => this.sections[section].type === "interview")
      .slice(-1)[0];

    const lastSectionName = this.sections[lastSection].name;

    return { lastSection, lastSectionName };
  };

  @action removeInterviewSection(sectionToRemove, onSuccess) {
    const hash = window.location.hash.replace(/#/g, "");
    const ref = this.dbRef;

    if (ref) {
      ref
        .child(`${hash}/sections/${sectionToRemove}/`)
        .set(null)
        .then((res) => {
          // Successfully deleted the section from DB
        })
        .catch((err) => {
          console.log("Couldn't remove section!");
        });
    }
  }

  @action onThumbnailUploadProgress = (progress) => {
    this.thumbnailUploadProgress = Math.floor(progress);
    console.log(this.thumbnailUploadProgress);
  };

  @action updateDocumentNameInDB = (newDocName) => {
    const hash = window.location.hash.replace(/#/g, "");
    const ref = this.dbRef;

    ref
      .child(hash)
      .update({
        docName: newDocName,
      })
      .then(() => {
        this.documentName = newDocName;
      })
      .catch((err) => {
        console.log("Error");
      });
  };

  uploadDocThumbnailToFirebase(docId, file, onSuccess, loadingCallback) {
    uploadDocThumbnail(docId, file, onSuccess, loadingCallback);
  }
}

export default DocStore;
