import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";

import CodeMirror from "codemirror";

// Components
import Header from "../header/header.component";
import CodeEditor from "../code-editor/code-editor.component";
import TextEditor from "../text-editor/text-editor.component";
import Footer from "../footer/footer.component";
import Tooltip from "../tooltip/tooltip.component";

// Stylesheet
import "./editor.styles.scss";
import EditorViewModel from "./EditorViewModel";

// Global Variable CodeMirror
window.CodeMirror = CodeMirror;

const Editor = (props) => {
  const {editorStore, docStore, walkthroughStore} = props;
  const hash = window.location.hash.replace(/#/g, "");
  const [viewModel, setViewModel] = useState({});
  // const [iframe,setIframe] = useState(false);

  useEffect(() => {
    const editorViewModel = new EditorViewModel({editorStore, docStore, walkthroughStore});
    setViewModel(editorViewModel);
    // setTimeout(()=>{
    //   let leftEditor = document.querySelector("#questionEditor .firepad .CodeMirror .CodeMirror-vscrollbar");
    //   let rightEditor = document.querySelector("#codeEditor .firepad .CodeMirror .CodeMirror-vscrollbar");
    //   window.leftEditor = leftEditor;
    //   window.rightEditor = rightEditor;
    //   leftEditor.scrollTo(0,leftEditor.scrollTop)
    //   rightEditor.scrollTo(0,rightEditor.scrollTop)
    // },1000)
  },[])


  // function logit() {
  //   console.log(new Date().getTime());
  // }

  // useEffect(() => {
  //   let leftEditor = document.querySelector("#questionEditor .firepad .CodeMirror .CodeMirror-vscrollbar");
  //   let rightEditor = document.querySelector("#codeEditor .firepad .CodeMirror .CodeMirror-vscrollbar");
  //   function watchScroll() {
  //     leftEditor?.addEventListener("scroll", logit);
  //   }
  //   watchScroll();
  //   return () => {
  //     leftEditor?.removeEventListener("scroll", logit);
  //   };
  // });


  return useObserver(() => (
    <React.Fragment>

      {viewModel.showWalkthrough && (
        <div className="walkthrough_container" id="walkthrough_container">
          <Tooltip />
        </div>
      )}

      <div className={"editor" + (viewModel.isIframe ? " iframe-view" : "")}>
        {
          !viewModel.isIframe && <Header docId={hash} />
        }
        <div className="editor__codeArea">
         <div className="editor__leftPanel">
           <TextEditor
              heading="Question"
              id="questionEditor"
              placeholder="Mentors write the questions here"
            />
            {/* <TextEditor
              heading="Interview Notes"
              id="feedbackEditor"
              placeholder="Feedback notes"
            /> */}
          </div>
          <CodeEditor heading="Work Area" id="codeEditor" />
        </div>
      </div>

      {!viewModel.isIframe && <Footer
        style={{ width: "92%", marginTop: "1em", paddingBottom: "0.5em" }}
      />}
    </React.Fragment>
  ));
};

export default compose(inject("editorStore"), inject("docStore"), inject("walkthroughStore"))(Editor);