import React, { useState, useEffect, useRef } from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";

import PreplacedLogoIcon from "../../assets/preplaced-logo-svg.svg";

// Material-UI
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";

// Stylesheet
import "./document-name-editor.styles.scss";
import DocumentNameEditorViewModel from "./DocumentNameEditorViewModel";

const DocumentNameEditor = ({ docStore }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const inputNameRef = useRef(null);
  const [viewModel, setViewModel] = useState({});

  useEffect(() => {
    const documentNameEditorViewModel = new DocumentNameEditorViewModel({docStore});
    setViewModel(documentNameEditorViewModel);
  }, [])

  const handleFocusEvent = (e) => {
    setIsEditMode(true);
  };

  const handleBlurEvent = (e) => {
    setIsEditMode(false);
  };

  useEffect(() => {
    if (inputNameRef) {
      inputNameRef.current.addEventListener("focus", handleFocusEvent);
      inputNameRef.current.addEventListener("blur", handleBlurEvent);
    }
  }, [inputNameRef]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      inputNameRef.current.blur();
    }
  };

  const handleChange = (e) => {
    viewModel.setDocumentNameInput(e.target.value);
  };

  const handleBlur = (e) => {
    const newName = viewModel.documentNameInput.trim();

    if (newName.length > 0 && newName !== docStore.documentName) {
      viewModel.updateDocumentNameInDB(newName);
    } else if (newName.length <= 0) {
      viewModel.updateDocumentNameInDB("untitled");
      alert("Please! enter a valid document name");
    }
  };

  const handleClick = (e) => {
    if (inputNameRef) {
      isEditMode ? inputNameRef.current.blur() : inputNameRef.current.focus();
    }
  };

  return useObserver(() => (
    <div className="documentNameEditor" id="documentName">
      <img
        className="documentNameEditor__preplacedLogo"
        src={PreplacedLogoIcon}
        alt="Preplaced"
      />

      <input
        className="documentNameEditor__input"
        value={viewModel && viewModel.documentNameInput || ''}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Enter document name"
        ref={inputNameRef}
      />

      <IconButton onClick={handleClick}>
        {isEditMode ? <CheckIcon /> : <EditIcon />}
      </IconButton>
    </div>
  ));
};

export default compose(inject("docStore"))(DocumentNameEditor);
