export const filterInterviewFrmAll = (allInterviews, interviewToBeRemoved) => {
  const interviewExists = allInterviews.find(
    (interview) => interview === interviewToBeRemoved
  );

  if (interviewExists) {
    return allInterviews.filter(
      (interview) => interview !== interviewToBeRemoved
    );
  }

  return allInterviews;
};
