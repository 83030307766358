import {computed} from "mobx"


class DefaultHeaderViewModel{
    constructor({userStore}){
        this.userStore = userStore;
    }
    
    @computed get currentUser(){
        return  this.userStore.currentUser
    }
}

export default DefaultHeaderViewModel;