import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Mobx
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";

// Material-UI
import { IconButton, Button, Modal, CircularProgress, Input } from "@material-ui/core";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import CloseIcon from "@material-ui/icons/Close";

import "./info-modal.styles.scss";
import InfomodalViewModel from "./InfoModalViewModel";

const InfoModal = (props) => {
  const {
    open,
    onClose,
    isEnd,
    isLoading,
    toggleCopyState,
    toggleEndState,
    docStore,
  } = props;

  const history = useHistory();
  const location = useLocation();

  const [copyInfo, setCopyInfo] = useState("");
  const [copyPasscodeInfo, setCopyPasscodeInfo] = useState("");
  const [isPasswordCopied, setIsPasswordCopied] = useState(false);
  const [isContentCopied, setIsContentCopied] = useState(false);
  const [viewModel, setViewModel] = useState({})

  useEffect(() => {
    const infoModalViewModel = new InfomodalViewModel({docStore});
    setViewModel(infoModalViewModel);
  },[])

  // This effect just loads the copy info to the variables
  useEffect(() => {
    setCopyInfo(
      `Join MyInterviewDoc: ${`${window.location.origin}${location.pathname}${location.hash}`}  passcode: ${
        viewModel.passcode
      }`
    );
  }, [location.pathname, location.hash, viewModel.passcode]);

  // This effect just loads the passcode info to thec copy variables
  useEffect(() => {
    setCopyPasscodeInfo(`${viewModel.passcode}`);
  }, [location.pathname, location.hash, viewModel.passcode]);

  // This effect is for the link copied message
  useEffect(() => {
    let timestamp = null;

    if (isPasswordCopied) {
      clearTimeout(timestamp);

      timestamp = setTimeout(() => {
        if (timestamp) setIsPasswordCopied(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timestamp);
      timestamp = null;
    };
  }, [isPasswordCopied]);

  // This effect is for the modal content copied message
  useEffect(() => {
    let timestampForContent = null;

    if (isContentCopied) {
      clearTimeout(timestampForContent);

      timestampForContent = setTimeout(() => {
        if (timestampForContent) setIsContentCopied(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timestampForContent);
      timestampForContent = null;
    };
  }, [isContentCopied]);

  const toggleIsCopied = () => {
    if (!isPasswordCopied) setIsPasswordCopied((cop) => !cop);
  };

  const handleCopiedClick = () => {
    if (!isContentCopied) setIsContentCopied(true);
  };

  const redirectToHome = () => {
    history.push("/");
  };

  return useObserver(() => (
    <Modal open={open} onClose={onClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="infoModal">
          {isEnd && (
            <React.Fragment>
              <span className="infoModal__heading">
                Are you sure you want to exit the interview?
              </span>

              <span className="infoModal__subHeading">
                Please save this information if you plan to come back to this
                doc.
              </span>
            </React.Fragment>
          )}

          <div className="infoModal__documentInfo">
            <div>
              <div className="documentInfo__heading">Join MyInterviewDoc:</div>
              <div className="documentInfo__link">
                {`${window.location.origin}${location.pathname}${location.hash}`}
              </div>
            </div>

            <div>
              <div className="documentInfo__heading">Passcode:</div>
              <div className="documentInfo__link documentInfo__link--spreadOut">
                {viewModel.passcode}
              </div>
            </div>

            {/* Close Button for copy info modal */}
            {!isEnd && (
              <span className="infoModal__closeButton">
                <IconButton onClick={toggleCopyState}>
                  <CloseIcon />
                </IconButton>
              </span>
            )}

            <CopyToClipboard text={copyPasscodeInfo} onCopy={toggleIsCopied}>
              <IconButton>
                <FilterNoneIcon className="copyDocumentInfo__copyIcon" /> Copy
                Passcode
              </IconButton>
            </CopyToClipboard>

            {/* "Password Copied" alert message */}
            <span
              className={`infoModal__copiedAlert ${
                isPasswordCopied
                  ? "infoModal__copiedAlert--open"
                  : "infoModal__copiedAlert--closed"
              }`}
            >
              Password copied!
            </span>
          </div>
          <div className="infoModal__email--invite">


          </div>
          <div className="infoModal__buttons">
            <CopyToClipboard text={copyInfo} onCopy={handleCopiedClick}>
              <Button className="infoModal__copyButton">
                {isContentCopied
                  ? "Copied to clipboard!"
                  : "Copy Doc Invitation"}
              </Button>
            </CopyToClipboard>

            {isEnd && (
              <div className="infoModal__exitButtons">
                <Button
                  className="infoModal__yesButton"
                  onClick={redirectToHome}
                >
                  Yes
                </Button>
                <Button
                  className="infoModal__endButton"
                  onClick={toggleEndState}
                >
                  No
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  ));
};

export default compose(inject("docStore"))(InfoModal);
