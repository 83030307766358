import React from "react";
import { Link } from "react-router-dom";

// Stylesheet
import "./page-not-found.styles.scss";

const PageNotFound = () => {
  return (
    <div className="pageNotFound">
      <h1 className="pageNotFound__heading">Not all who wander are lost,</h1>
      <br />
      <h1 className="pageNotFound__heading">but we think you are.</h1>

      <h2 className="pageNotFound__message">
        The page you are looking for doesn't exist or has been moved.
      </h2>

      <Link to="/" className="pageNotFound__goBackHome">
        Return Home
      </Link>
    </div>
  );
};

export default PageNotFound;
