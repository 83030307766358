import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";
import { reaction } from "mobx";

import CodeMirror from "codemirror";

// Code Mirror Placeholder Addon
import "codemirror/addon/display/placeholder";

// Default CodeMirror CSS
import "codemirror/lib/codemirror.css";

// Default Firepad CSS
import "firepad/dist/firepad.css";

// Firebase
import { databaseRef } from "../../firebase/firebase";

// Stylesheets
import "./text-editor.styles.scss";
import TextEditorViewModel from "./TextEditorViewModel";

const TextEditor = ({ heading, id, placeholder, editorStore }) => {
  const [viewModel, setViewModel] = useState({})

  useEffect(() => {
    const textEditorViewModel = new TextEditorViewModel({editorStore});
    setViewModel(textEditorViewModel);
  },[])

  const getEditorRef = () => {
    const hash = window.location.hash.replace(/#/g,"");
    if (hash && databaseRef){
      return databaseRef
        .child(hash)
        .child("sections")
        .child(viewModel.currentSection)
        .child("data")
        .child(id);
    }
    return null;
  };

  const onCurrentInterviewChange = () => {
    // Get Firebase Database reference.
    var firepadRef = getEditorRef();

    const theElement = document.getElementById(id);

    if (theElement) {
      const parentElement = theElement.parentNode;
      theElement.remove();

      const newElement = document.createElement("div");
      const newElementHeading = document.createElement("h1");

      newElementHeading.textContent = heading;
      newElementHeading.className = "textEditor__heading";

      newElement.className = "textEditor";
      newElement.id = id;

      newElement.appendChild(newElementHeading);
      parentElement.appendChild(newElement);
    }

    var codeMirror = CodeMirror(document.getElementById(id), {
      mode: "text/plain",
      theme: "material",
      placeholder: "Mentors workspace",
    })

  //   codeMirror.on('change', editor => {
  //     console.log(editor.getValue());
  // });


    // console.log("🚀 ~ file: text-editor.component.jsx ~ line 73 ~ codeMirror ~ codeMirror", codeMirror)

    const Firepad2 = require("firepad");

    // Create Firepad.
    Firepad2.fromCodeMirror(firepadRef, codeMirror, {
      richTextToolbar: true,
      richTextShortcuts: true
    })


    // var headless = new Firepad2.Headless(firepadRef);
    // headless.getText(function(text) {
    //   console.log("Contents of firepad retrieved: " + text);
    // });

    // headless.setHtml('<b>Welcome to Firepad!</b>', function(err, committed) {
    //   // err       will be set if there was a catastrophic failure
    //   // committed will be true on success, or false if there was a history
    //   //               conflict writing to the pad's history.
    //   console.log("err",err)
    // });
  };

  reaction(
    () => viewModel.currentSection,
    (currentSection) => {
      if (currentSection) {
        onCurrentInterviewChange();
      }
    }
  );

  return useObserver(() => (
    <div className="textEditor" id={id}>
      <h2 className="textEditor__heading">{heading}</h2>
    </div>
  ));
};

export default compose(inject("editorStore"))(TextEditor);
