import React, { useEffect, useState } from "react";

// Mobx
import { compose } from "recompose";
import { inject, useObserver } from "mobx-react";
import { reaction } from "mobx";

import CodeMirror from "codemirror";

// Default CodeMirror CSS
import "codemirror/lib/codemirror.css";

// Code Mirror Placeholder Addon
import "codemirror/addon/display/placeholder";

// Themes
import "codemirror/theme/material.css";
import "codemirror/theme/oceanic-next.css";
import "codemirror/theme/solarized.css";
import "codemirror/theme/midnight.css";

// Modes
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/clike/clike";
import "codemirror/mode/python/python";
import "codemirror/mode/go/go";
import "codemirror/mode/htmlembedded/htmlembedded";
import "codemirror/mode/php/php";

import { databaseRef } from "../../firebase/firebase";

// Stylesheets
import "./code-editor.styles.scss";
import CodeEditorViewModel from "./CodeEditorViewModel";

const CodeEditor = ({ heading, id, editorStore }) => {
  const [viewModel, setViewModel] = useState({});

  useEffect(() => {
    const codeEditorViewModel = new CodeEditorViewModel({ editorStore });
    setViewModel(codeEditorViewModel);
  }, []);

  const getEditorRef = () => {
    const hash = window.location.hash.replace(/#/g, "");

    if (hash && databaseRef) {
      return databaseRef
        .child(hash)
        .child("sections")
        .child(viewModel.currentSection)
        .child("data")
        .child("codeEditor");
    }

    return null;
  };

  const onCurrentInterviewChange = () => {
    // Get Firebase Database reference.
    console.log("at onCurrentInterviewChange");
    var firepadRef = getEditorRef();

    const theElement = document.getElementById(id);

    if (theElement) {
      const parentElement = theElement.parentNode;
      theElement.remove();

      const newElement = document.createElement("div");

      newElement.className = "codeEditor";
      newElement.id = id;

      parentElement.appendChild(newElement);
    }

    // Create CodeMirror
    var codeMirror = CodeMirror(document.getElementById(id), {
      lineNumbers: true,
      mode: viewModel.language || "text/plain",
      theme: "material",
      placeholder: "Mentees answers the questions here",
    });

    const Firepad1 = require("firepad");

    // Create Firepad.
    var firepad = Firepad1.fromCodeMirror(firepadRef, codeMirror, {
      richTextToolbar: true,
      richTextShortcuts: true,
    });

    viewModel.setEditor(codeMirror);
  };

  reaction(
    () => viewModel.currentSection,
    (currentSection) => {
      if (currentSection) {
        onCurrentInterviewChange();
      }
    }
  );

  const onLanguageChange = (e) => {
    const { value } = e.target;

    viewModel.changeLanguageInDB(value);
  };

  return useObserver(() => (
    <div className="codeEditor__container">
      <div className="codeEditor__container__header">
        <h2 className="codeEditor__heading">{heading}</h2>
        <select
          className="codeEditor__options"
          onChange={onLanguageChange}
          value={viewModel.language}
          id="select_language"
        >
          <option value="text/plain">Plain Text</option>
          <option value="javascript">Javascript</option>
          <option value="python">Python</option>
          <option value="text/x-csrc">C</option>
          <option value="text/x-c++src">C++</option>
          <option value="text/x-java">Java</option>
          <option value="text/x-go">Go</option>
          <option value="text/x-php">PHP</option>
          <option value="application/x-aspx">.NET</option>
        </select>
      </div>

      <div className="codeEditor" id={id}></div>
    </div>
  ));
};

export default compose(inject("editorStore"))(CodeEditor);
