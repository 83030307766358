import React from "react";
// import { compose } from "recompose";
// import { inject, useObserver } from "mobx-react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, redirectTo, ...rest }) => {
  let accessTokenPresent = () => localStorage.getItem("accessToken");

  return (
    <Route
      {...rest}
      render={(props) =>
        accessTokenPresent() ? (
          <Redirect to={redirectTo} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default ProtectedRoute;
