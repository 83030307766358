import {action, computed} from "mobx"

class DocumentNameEditorViewModel{
    constructor({docStore}){
        this.docStore = docStore;
    } 

    @computed get documentName(){
        return this.docStore.documentName
    }
    @computed get documentNameInput(){
        return this.docStore.documentNameInput
    }
    
    @action updateDocumentNameInDB = (newName) => {
        this.docStore.updateDocumentNameInDB(newName)
    }

    @action setDocumentName = (value) => {
        this.docStore.setDocumentName(value)
    }

    @action setDocumentNameInput = (value) => {
        this.docStore.setDocumentNameInput(value)
    }

    @action getFullDocData = (doc, onSuccessfulFetch) => {
        this.docStore.getFullDocData(doc, onSuccessfulFetch)
    }
}

export default DocumentNameEditorViewModel;