import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import config from "../env.config";

var firebaseConfig = {
  apiKey: config.REACT_APP_API_KEY,
  authDomain: config.REACT_APP_AUTH_DOMAIN,
  databaseURL: config.REACT_APP_DATABASE_URL,
  projectId: config.REACT_APP_PROJECT_ID,
  storageBucket: config.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: config.REACT_APP_MESSAGING_SENDER_ID,
  appId: config.REACT_APP_ID,
};

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

// Firestore
export const firestoreDB = firebase.firestore();

// Realtime Database
export const databaseRef = firebase.database().ref();

export const firebaseDB = firebase.database();

// Firebase Storage
export const firebaseStorage = firebase.storage().ref();

// Authentication
export const auth = firebase.auth();

// Deleting local Data
const deleteLocalOwnedDocs = () => {
  localStorage.removeItem("ownedDocs");
};

const deleteLocalRecentDocs = () => {
  localStorage.removeItem("recentDocs");
};

const deleteLocalSharedDocs = () => {
  localStorage.removeItem("sharedDocs");
};

const createOrUpdateUser = async (user) => {
  try {
    // Check whether user exists in firestore DB, if not add him to DB
    const docRef = firestoreDB.collection("users").doc(user.email);
    const docSnapshot = await docRef.get();

    // fetching the local data
    let ownedDocs = JSON.parse(window.localStorage.getItem("ownedDocs")) || [];
    let recentDocs =
      JSON.parse(window.localStorage.getItem("recentDocs")) || [];
    let sharedDocs =
      JSON.parse(window.localStorage.getItem("sharedDocs")) || [];

    // User exists in firestore so update doc frm local
    if (docSnapshot.exists) {
      let userRecentDocs = docSnapshot.data().recentDocs;
      let userSharedDocs = docSnapshot.data().sharedDocs;
      let userOwnedDocs = docSnapshot.data().ownedDocs;

      if (recentDocs.length > 0) {
        userRecentDocs = [...new Set([...userRecentDocs, ...recentDocs])];
      }

      // TODO: Owned docs from local should take precedence!!
      if (ownedDocs.length > 0) {
        ownedDocs.forEach((owDoc) => {
          if (!userOwnedDocs.includes(owDoc) && !userSharedDocs.includes(owDoc))
            userOwnedDocs.push(owDoc);
        });
      }

      if (sharedDocs.length > 0) {
        sharedDocs.forEach((shDoc) => {
          if (!userSharedDocs.includes(shDoc) && !userOwnedDocs.includes(shDoc))
            userSharedDocs.push(shDoc);
        });
      }

      await docRef.update({
        recentDocs: userRecentDocs,
        ownedDocs: userOwnedDocs,
        sharedDocs: userSharedDocs,
      });

      // Adding new user to the firestoreDB
    } else {
      await docRef.set({
        email: user.email,
        username: user.displayName || null,
        photoURL: user.photoURL || null,
        ownedDocs,
        recentDocs,
        sharedDocs,
      });
    }

    // Deleting the local data
    deleteLocalOwnedDocs();
    deleteLocalRecentDocs();
    deleteLocalSharedDocs();
  } catch (err) {
    console.log("Error: ", err.message);
    return;
  }
};

// Google Auth
const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
export const SignInWithGoogle = async (successCallback) =>
  auth
    .signInWithPopup(googleProvider)
    .then(async (result) => {
      try {
        var user = result.user;

        await createOrUpdateUser(user);

        // Callback, usually redirecting the user or closing the popup.
        successCallback && successCallback();
      } catch (err) {
        successCallback && successCallback();
      }
    })
    .catch((error) => {
      console.log("Error: ", error.message);
    });

// Github Auth
const githubProvider = new firebase.auth.GithubAuthProvider();
export const SignInWithGithub = (successCallback) =>
  auth
    .signInWithPopup(githubProvider)
    .then(async (result) => {
      try {
        var user = result.user;

        await createOrUpdateUser(user);

        // Callback, usually redirecting the user or closing the popup.
        successCallback && successCallback();
      } catch (err) {
        successCallback && successCallback();
      }
    })
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
      console.log("Login error: ", errorCode, errorMessage, email, credential);
    });

// Create account with Email & Password
export const createAccountWithEmailAndPassword = (newUser, successCallback) => {
  auth
    .createUserWithEmailAndPassword(newUser.email, newUser.password)
    .then(async (userCredential) => {
      try {
        const currentUser = userCredential.user;
        await currentUser.updateProfile({
          displayName: newUser.username,
        });

        const USER = auth.currentUser;
        await createOrUpdateUser(USER);

        // Callback, usually redirecting the user or closing the popup.
        successCallback && successCallback();
      } catch (err) {
        successCallback && successCallback();
      }
    })
    .catch((error) => {
      const errorMessage = error.message;

      alert(errorMessage);
    });
};

// Sign-in with Email & Password
export const signInAccountWithEmailAndPassword = (user, successCallback) => {
  auth
    .signInWithEmailAndPassword(user.email, user.password)
    .then(async (userCredential) => {
      try {
        var user = userCredential.user;

        await createOrUpdateUser(user);

        // Callback, usually redirecting the user or closing the popup.
        successCallback && successCallback();
      } catch (err) {
        successCallback && successCallback();
      }
    })
    .catch((error) => {
      const errorMessage = error.message;

      alert(errorMessage);
    });
};

// Fetch user docs from firestore
export const fetchUserDocsFromFirestore = (email, onSuccess, onFailure) => {
  firestoreDB
    .collection("users")
    .doc(email)
    .get()
    .then((data) => {
      onSuccess(data.data());
    })
    .catch((err) => {
      onFailure(false);
    });
};

// Add document Id to Recent Docs in user firestore
export const addDocIdInRecentDocs = async (docId, userAuth) => {
  if (userAuth) {
    try {
      const userDocRef = firestoreDB.collection("users").doc(userAuth.email);
      const snapshotOfUser = await userDocRef.get();

      let recentDocs = snapshotOfUser.data().recentDocs;

      const isDocExistsInRecentDocs = recentDocs.includes(docId);

      if (isDocExistsInRecentDocs) {
        recentDocs = recentDocs.filter((recentDoc) => recentDoc !== docId);
      }

      recentDocs.push(docId);

      await userDocRef.update({
        recentDocs,
      });
    } catch (err) {
      // Do something
    }
  }
};

// Add document Id to Owned, Recent Docs in user firestore
export const addDocIdInOwnedDocs = async (
  docId,
  userAuth,
  onSuccess,
  onFailure
) => {
  if (userAuth) {
    try {
      const userDocRef = firestoreDB.collection("users").doc(userAuth.email);
      const snapshotOfUser = await userDocRef.get();

      if (snapshotOfUser.exists) {
        let recentDocs = snapshotOfUser.data().recentDocs;
        let ownedDocs = snapshotOfUser.data().ownedDocs;

        const isDocIdInOwnedDocs = ownedDocs.includes(docId);
        const isDocIdInRecentDocs = recentDocs.includes(docId);

        if (isDocIdInRecentDocs) {
          recentDocs = recentDocs.filter((recentDoc) => recentDoc !== docId);
        }

        recentDocs.push(docId);

        if (!isDocIdInOwnedDocs) {
          ownedDocs.push(docId);
        }

        await userDocRef.update({
          ownedDocs,
          recentDocs,
        });

        onSuccess(docId);
      }
    } catch (err) {
      onFailure(docId);
    }
  }
};

// Add document Id to Shared, Recent Docs in user firestore
export const addDocIdInSharedDocs = async (docId, userAuth) => {
  if (userAuth) {
    try {
      const userDocRef = firestoreDB.collection("users").doc(userAuth.email);
      const snapshotOfUser = await userDocRef.get();

      let sharedDocs = snapshotOfUser.data().sharedDocs;
      let recentDocs = snapshotOfUser.data().recentDocs;

      const isDocExistsInSharedDocs = sharedDocs.includes(docId);
      const isDocExistsInRecentDocs = recentDocs.includes(docId);

      if (!isDocExistsInSharedDocs) {
        sharedDocs.push(docId);
      }

      if (isDocExistsInRecentDocs) {
        recentDocs = recentDocs.filter((recentDoc) => recentDoc !== docId);
      }

      recentDocs.push(docId);

      await userDocRef.update({
        sharedDocs,
        recentDocs,
      });

      const documentSnapshot = await databaseRef.child(docId).once("value");
      const sharedWithArr = documentSnapshot.val().sharedWith;

      // Property "sharedWith" already exists in the DB
      if (sharedWithArr) {
        const isDocAlreadySharedWithUser = sharedWithArr.find(
          (sharedWith) => sharedWith === userAuth.email
        );

        if (!isDocAlreadySharedWithUser) {
          await databaseRef.child(`${docId}/`).update({
            sharedWith: [...documentSnapshot.val().sharedWith, userAuth.email],
          });
        }

        // Property "sharedWith" does not exist, so we add it
      } else {
        await databaseRef.child(`${docId}/`).update({
          sharedWith: [userAuth.email],
        });
      }

      // Something went wrong above soo ERROR!
    } catch (err) {
      // Do something...
    }
  }
};

// Checking if the logged in user owns the doc
export const checkIfLoggedInUserOwnsTheDoc = async (
  docId,
  userAuth,
  onSuccessAuthenticate,
  onFailureAuthenticate
) => {
  if (userAuth) {
    try {
      const userDocRef = firestoreDB.collection("users").doc(userAuth.email);
      const snapshotOfUser = await userDocRef.get();

      const documentSnapshot = await databaseRef.child(docId).once("value");
      const ownerAcordingToDoc = documentSnapshot.val().owner;

      // let recentDocs = snapshotOfUser.data().recentDocs;
      let ownedDocs = snapshotOfUser.data().ownedDocs;
      let sharedDocs = snapshotOfUser.data().sharedDocs;

      const isDocInOwnedDocs = ownedDocs.includes(docId);
      const isDocInSharedDocs = sharedDocs.includes(docId);

      // Adding the doc to recent docs bcz it doesnt matter if the user is the owner or not
      await addDocIdInRecentDocs(docId, userAuth);

      // If he is the owner accoring to rDB and firestore, then add the doc to recent store and just authenticate
      if (ownerAcordingToDoc === userAuth.email && isDocInOwnedDocs) {
        onSuccessAuthenticate();
        return;
      }
      // But he is the owner according to firestore, and rDb's info is unknow. SO, we update it
      else if (
        isDocInOwnedDocs &&
        ownerAcordingToDoc !== userAuth.email &&
        ownerAcordingToDoc === "unknown"
      ) {
        await databaseRef.child(docId).update({
          owner: userAuth.email,
        });

        onSuccessAuthenticate();
        return;
      }
      // But, he is the owner according to rDB, but according to firestore he is not. So we update in firestore
      else if (
        ownerAcordingToDoc === userAuth.email &&
        !isDocInOwnedDocs &&
        !isDocInSharedDocs
      ) {
        ownedDocs.push(docId);

        await userDocRef.update({
          ownedDocs,
        });

        onSuccessAuthenticate();
        return;
      }

      // He is not the owner so we need to add this document to shared documents
      else {
        await addDocIdInSharedDocs(docId, userAuth);
      }

      onFailureAuthenticate();
    } catch (err) {
      onFailureAuthenticate();
    }
  }
};

// Storing the Doc details locally if the used is not logged in
export const storeDocIdLocally = (
  docId,
  onSuccessAuthenticate,
  onFailureAuthenticate
) => {
  let ownedDocs = JSON.parse(window.localStorage.getItem("ownedDocs")) || [];
  let recentDocs = JSON.parse(window.localStorage.getItem("recentDocs")) || [];
  let sharedDocs = JSON.parse(window.localStorage.getItem("sharedDocs")) || [];

  const isDocInRecentDocs = recentDocs.includes(docId);
  const isDocInSharedDocs = sharedDocs.includes(docId);
  const isDocInOwnedDocs = ownedDocs.includes(docId);

  // Editing Recent Docs
  if (isDocInRecentDocs) {
    recentDocs = recentDocs.filter((recentDoc) => recentDoc !== docId);
  }

  // Updating Recent Docs
  recentDocs.push(docId);
  window.localStorage.setItem("recentDocs", JSON.stringify(recentDocs));

  // Checking if the doc is in ownerDocs
  if (isDocInOwnedDocs) {
    onSuccessAuthenticate();
    return;
  }

  // Adding it in shared doc if its not there in ownerDocs
  if (!isDocInSharedDocs && !isDocInOwnedDocs) {
    sharedDocs.push(docId);

    window.localStorage.setItem("sharedDocs", JSON.stringify(sharedDocs));
  }

  onFailureAuthenticate();
};

// Upload Files In Firebase Storage
const uploadFile = async (
  docId,
  filePath,
  file,
  successCallback,
  loadingCallback
) => {
  try {
    const fileReference = firebaseStorage.child(filePath);
    const uploadTask = await fileReference.put(file);

    const downloadURL = await uploadTask.ref.getDownloadURL();

    await databaseRef.child(`${docId}/`).update({
      documentThumbnail: downloadURL,
    });

    successCallback();
  } catch (err) {
    successCallback();
  } finally {
    loadingCallback();
  }
};

// Upload Doc Thumbnail In Firebase Storage
export const uploadDocThumbnail = (
  docId,
  file,
  successCallback,
  loadingCallback
) => {
  let fileName = "doc" + docId;
  let filePath = "Doc Thumbnails/" + fileName;

  uploadFile(docId, filePath, file, successCallback, loadingCallback);
};

export default firebase;
