import {action, autorun, computed} from "mobx"

class HomeViewModel{
    constructor({userStore, docStore, editorStore}){
        this.userStore = userStore;
        this.docStore = docStore;
        this.editorStore = editorStore;
        this.userStore.setIsDocsFetchLoading(true);
        autorun(() =>{
            console.error("home view model autorun called");
            if (this.userStore.currentUser){
                this.userStore.addSnapshotObserver();
            }
        });
        // this.fetchDocsForUser();
    } 

    @computed get currentUser(){
        return  this.userStore.currentUser;
    }

    @computed get sharedDocs(){
        return  this.userStore.sharedDocs;
    }
    
    @computed get isDocsFetchLoading(){
        return this.userStore.isDocsFetchLoading;
    }

    @computed get ownedDocs(){
        return  this.userStore.ownedDocs;
    }

    @computed get recentDocs(){
        return  this.userStore.recentDocs;
    }

    @computed get recentDocsLength(){
        return this.userStore.recentDocs.length;
    }

    @computed get ownedDocsLength(){
        return this.userStore.ownedDocs.length;
    }

    @computed get sharedDocsLength(){
        return this.userStore.sharedDocs.length;
    }

    @action authenticateAndChangeRoute = (history, hash) => {
        this.editorStore.authenticateAndChangeRoute(history, hash);
    }

    @action createNewDocAndRedirect = (newEditorObj) => {
        this.docStore.createNewDocAndRedirect(newEditorObj);
    }

    @action fetchDocsForUser = () => {
        this.userStore.fetchDocsForUser();
    }
    
    @action redirectAndOpenModal = (history, hash) => {
        this.editorStore.redirectAndOpenModal(history, hash);
    }

}

export default HomeViewModel