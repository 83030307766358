import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

// Firebase
import { auth } from "../../firebase/firebase";

// Material-UI
import { Button } from "@material-ui/core";

// Stylesheet
import "./reset-password.styles.scss";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  const location = useLocation();
  const history = useHistory();
  var code = location.search.split("&")[1].split("=")[1];

  useEffect(() => {
    auth
      .verifyPasswordResetCode(code)
      .then(function (email) {
        console.log(email);
      })
      .catch(function () {
        history.push("/email-verification");
      });
  }, []);

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Password does not match");
      return;
    }
    setProcessing(true);
    auth
      .confirmPasswordReset(code, newPassword)
      .then(function () {
        setError("");
        setProcessing(false);
        alert("Password Changed Successfully");
      })
      .catch(function (error) {
        setProcessing(false);
        setError(error.message);
      });
  };

  return (
    <div className="resetPasswordCard">
      <h1 className="resetPasswordCard__heading">Reset Password</h1>

      <form onSubmit={handleSubmit}>
        <input
          type="password"
          name="new password"
          value={newPassword}
          onChange={handleNewPassword}
          placeholder="Enter new password"
          required
          disabled={processing}
        />

        <input
          type="password"
          name="confirm password"
          value={confirmPassword}
          onChange={handleConfirmPassword}
          placeholder="Confirm password"
          required
          disabled={processing}
        />

        {error && <p className="passwordResetError">{error}</p>}

        <Button
          type="submit"
          className="changePasswordBtn"
          disabled={processing}
        >
          Change Password
        </Button>
      </form>
    </div>
  );
};
export default ResetPassword;
