import EDITOR_ACTION_TYPES from "./editor.types";
import { filterInterviewFrmAll } from "./editor.utils";

const INITIAL_STATE = {
  allInterviews: [],
  currentInterview: null,
};

const editorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EDITOR_ACTION_TYPES.SET_CURRENT_INTERVIEW:
      return {
        ...state,
        currentInterview: action.payload,
      };

    case EDITOR_ACTION_TYPES.SET_INITIAL_INTERVIEWS:
      return {
        ...state,
        allInterviews: action.payload,
        currentInterview: action.payload[0],
      };

    case EDITOR_ACTION_TYPES.ADD_NEW_INTERVIEW:
      return {
        ...state,
        allInterviews: [...state.allInterviews, action.payload],
        currentInterview: action.payload,
      };

    case EDITOR_ACTION_TYPES.REMOVE_INTERVIEW:
      const res = filterInterviewFrmAll(
        state.allInterviews,
        state.currentInterview
      );
      return {
        ...state,
        allInterviews: res,
        currentInterview: res[0] || null,
      };

    default:
      return state;
  }
};

export default editorReducer;
