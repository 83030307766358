import {computed} from "mobx"


class RootLandingViewModel{
    constructor({userStore}){
        this.userStore = userStore;
    }
    
    @computed get currentUser(){
        return this.userStore.currentUser
    }
}

export default RootLandingViewModel;