import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { inject, useObserver } from "mobx-react";
import { compose } from "recompose";
import moment from "moment";

import { ReactComponent as PreplacedLogoIcon } from "../../assets/preplaced-logo-svg.svg";

// Stylesheet
import "./interview-preview.styles.scss";
import InterviewPreviewViewModel from "./InterviewPreviewViewModel";

const InterviewPreview = ({ doc, docStore }) => {
  const [cardData, setCardData] = useState({});
  const [viewModel, setViewModel] = useState({});
  const history = useHistory();

  useEffect(() => {
    const interviewPreviewViewModel = new InterviewPreviewViewModel({doc, docStore, onSuccessfulFetch});
    setViewModel(interviewPreviewViewModel);
  }, []);

  const onSuccessfulFetch = (data) => {
    setCardData(data);
  };

  const getTimeDifference = (firebaseDate) => {
    const previous = moment(firebaseDate);
    const present = moment(Date.now());

    const difference = previous.from(present);

    return difference;
  };

  const goToEditor = () => {
    history.push(`/editor/#${doc.toString()}`);
  };

  return useObserver(() => (
    <div className="interviewPreview" onClick={goToEditor}>
      <div className="interviewPreview__previewImage">
        {cardData?.documentThumbnail ? (
          <img src={cardData?.documentThumbnail} alt="preview" />
        ) : (
          <PreplacedLogoIcon className="previewImage__noImage" />
        )}
      </div>

      <div className="interviewPreview__details">
        <span className="interviewPreview__title">
          {cardData?.docName?.length > 30
            ? `${cardData?.docName.slice(0, 25) + "..."}`
            : cardData?.docName}
        </span>
        <div className="interviewPreview__extraInfo">
          <span className="extraInfo__owner" title={cardData?.owner}>
            {cardData?.owner?.length > 18
              ? `${cardData?.owner.slice(0, 16) + "..."}`
              : cardData?.owner}
          </span>
          <span className="extraInfo__date">
            {cardData?.createdAt && getTimeDifference(cardData?.createdAt)}
          </span>
        </div>
      </div>
    </div>
  ));
};

export default compose(inject("docStore"))(InterviewPreview);
