import React, { useState } from "react";

import {
  auth
} from "../../firebase/firebase";

// Material-UI
import { Button, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./email-verification.styles.scss";

const EmailVerification = ({goToLogin}) => {
  const [email, setEmail] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const handleChange = (e) => {
    setEmail(e.target.value)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSendingEmail(true)
    auth.sendPasswordResetEmail(email)
    .then(response => {
      setSendingEmail(false)
      setEmailSent(true)
    })
    .catch(error => {
      setSendingEmail(false)
      alert(error.message)
    })
  };

  return (
    <div className="emailCard">
      <h1 className="emailCard__heading">
          Email Verification
      </h1>

      <form onSubmit={handleSubmit}>
        {!emailSent ? 
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            placeholder="Enter Your Registered Email"
            required
            disabled={sendingEmail}
          />
        :
        <p className="emailSentMsg">Password reset link has been sent to your registered email</p>
        }

        <Button type="submit" className="sendPasswordResetLink" disabled={sendingEmail} >
          { !emailSent ? "Send Password Reset Link" : "Resend Password Reset Link"}
        </Button>
        {goToLogin && <Button onClick={goToLogin} className="loginNavButton">
        Back To Login
      </Button>}
      </form>
    </div>
  )
}

export default EmailVerification