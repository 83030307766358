import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { inject, useObserver } from "mobx-react";
import { compose } from "recompose";

import PreplacedLogo from "../preplaced-logo/preplaced-logo.component";
import UserAvatar from "../user-avatar/user-avatar.component";

// Stylesheets
import "./preplaced-default-header.styles.scss";
import DefaultHeaderViewModel from "./DefaultHeaderViewModel";

const PreplacedDefaultHeader = ({ userStore, children }) => {
  const history = useHistory();
  const [viewModel, setViewModel] = useState({})

  useEffect(() => {
    const defaultHeaderViewModel = new DefaultHeaderViewModel({userStore});
    setViewModel(defaultHeaderViewModel)
  }, [])

  const redirectToLoginPage = () => {
    history.push("/login");
  };

  return useObserver(() => (
    <div className="preplacedDefaultHeader__container">
      <PreplacedLogo />

      {children ? (
        children
      ) : viewModel.currentUser ? (
        <UserAvatar />
      ) : (
        <button className="header__loginButton" onClick={redirectToLoginPage}>
          Login/Register
        </button>
      )}
    </div>
  ));
};

export default compose(inject("userStore"))(PreplacedDefaultHeader);
