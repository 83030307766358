import { action, observable, reaction } from "mobx";

import { firestoreDB, fetchUserDocsFromFirestore } from "../firebase/firebase";

class UserStore {
  @observable currentUser = null;
  @observable recentDocs = [];
  @observable sharedDocs = [];
  @observable ownedDocs = [];
  @observable isDocsFetchLoading = true;
  @observable subscription = null;

  constructor(dbRef) {
    this.dbRef = dbRef;

    // reaction to fetch all docs for user
    // reaction(
    //   () => this.currentUser,
    //   (currentUser) => {
    //     if (currentUser) {
    //       this.setIsDocsFetchLoading(true);
    //       this.fetchDocsForUser();
    //     } else {
    //       this.setIsDocsFetchLoading(false);

    //       this.setRecentDocs([]);
    //       this.setSharedDocs([]);
    //       this.setOwnedDocs([]);
    //     }
    //   }
    // );
  }

  fetchDocsForUser = () => {
    if (this.currentUser) {
      fetchUserDocsFromFirestore(
        this.currentUser.email,
        this.setAllUserDocs
      );
    } else {
      this.setIsDocsFetchLoading(false);
    }
  };

  setAllUserDocs = (docsData) => {
    const extractedData = {};
    if (docsData) {
      Object.keys(docsData).forEach(function (field) {
        if (
          field === "recentDocs" ||
          field === "sharedDocs" ||
          field === "ownedDocs"
        ) {
          extractedData[field] = docsData[field];
        }
      });

      this.setRecentDocs(extractedData["recentDocs"]);
      this.setSharedDocs(extractedData["sharedDocs"]);
      this.setOwnedDocs(extractedData["ownedDocs"]);
    }
    // We set the loading state to false after we fetch the data and set the data here
    this.setIsDocsFetchLoading(false);
  };

  compareArrays = (oldArr, newArr) => {
    const newArrSorted = newArr.slice().sort();
    const oldArrSorted = oldArr.slice().sort();

    return (
      oldArrSorted.length === newArrSorted.length &&
      oldArrSorted.every((value, index) => value === newArrSorted[index])
    );
  };

  // A listener for all the docs
  addSnapshotObserver = () => {
    if (this.currentUser && firestoreDB) {
      this.subscription = firestoreDB
        .collection("users")
        .doc(this.currentUser.email)
        .onSnapshot((snapshot) => {
          let recentDocs = [],
            sharedDocs = [],
            ownedDocs = [];

          if (snapshot.exists) {
            recentDocs = snapshot.data().recentDocs;
            ownedDocs = snapshot.data().ownedDocs;
            sharedDocs = snapshot.data().sharedDocs;
          }

          if (!this.compareArrays(this.recentDocs, recentDocs))
            this.setRecentDocs(recentDocs);

          if (!this.compareArrays(this.sharedDocs, sharedDocs))
            this.setSharedDocs(sharedDocs);

          if (!this.compareArrays(this.ownedDocs, ownedDocs))
            this.setOwnedDocs(ownedDocs);
          this.setIsDocsFetchLoading(false);
        });
    }
  };

  removeSnapshotObserver = () => {
    this.subscription && this.subscription();
  };

  @action setCurrentUser(user) {
    this.currentUser = user;
  }

  @action setRecentDocs(data) {
    this.recentDocs = data;
  }

  @action setSharedDocs(data) {
    this.sharedDocs = data;
  }

  @action setOwnedDocs(data) {
    this.ownedDocs = data;
  }

  @action setIsDocsFetchLoading(loading) {
    this.isDocsFetchLoading = loading;
  }
}

export default UserStore;
