import {computed} from 'mobx';

class TextEditorViewModel {
    constructor({editorStore}){
        this.editorStore = editorStore;
    }

    @computed get currentSection(){
        return this.editorStore.currentSection
    }
}


export default TextEditorViewModel;