import { action, observable, computed } from "mobx";

class WalkthroughStore {
  constructor() {
    this.setTooltipPosition = this.setTooltipPosition.bind(this);
    window.onresize = this.setTooltipPosition;
    window.onscroll = this.setTooltipPosition;
  }

  @observable componentArray = [
    {
      id: "documentName",
      position: "bottom",
      heading: "Document Name",
      message: "Enter your document name!",
    },
    {
      id: "sectionDropdown",
      position: "bottom",
      heading: "Interview Sections",
      message:
        "Select, Create, Update, Delete your interview sections from here!",
    },
    {
      id: "questionEditor",
      class1: ".firepad",
      class2: ".firepad-toolbar-wrapper",
      position: "bottom",
      heading: "Toolbar",
      message:
        "Make your text more readable and understandable with these stylings!",
    },
    {
      id: "questionEditor",
      class1: ".firepad",
      class2: ".CodeMirror",
      position: "bottom",
      heading: "Question's Space",
      message: "Interviewers can write the questions here!",
    },
    {
      id: "feedbackEditor",
      class1: ".firepad",
      class2: ".CodeMirror",
      position: "top",
      heading: "Notes",
      message: "Write important points/notes here!",
    },
    {
      id: "codeEditor",
      class1: ".firepad",
      class2: ".CodeMirror",
      position: window.innerWidth <= 1024 ? "top" : "left",
      heading: "Solution Space",
      message: "Space to write your answers.",
    },
    {
      id: "select_language",
      position: "left",
      heading: "Programming Language",
      message: "Select your preferred programming language from here!",
    },
    {
      id: "inviteButton",
      position: "bottom",
      heading: "Invite",
      message: "Invite others to join the interview.",
    },
    {
      id: "endInterviewButton",
      position: "bottom",
      heading: "End Interview",
      message:
        "Once you are done with the interview, you can exit by clicking this button.",
    },
  ];

  @observable componentIndex = 0;

  @computed get currentComponentData() {
    try {
      var data = this.componentArray[this.componentIndex];

      return { heading: data?.heading, message: data?.message };
    } catch (e) {
      console.error("error occurred while returning current component data");
    }
  }

  @action firstComponentWalkthrough = () => {
    if (!localStorage.getItem("walkthrough")) {
      document
        .getElementById(this.componentArray[0].id)
        ?.classList.add("zIndexForComponent");
      this.setTooltipPosition();
    }
  };

  @computed get currentElementId() {
    return (
      this.componentArray[this.componentIndex]?.id ||
      this.componentArray.length - 1
    );
  }

  @computed get currentElement() {
    try {
      return document
        .getElementById(this.currentElementId)
        .querySelector(this.componentArray[this.componentIndex]?.class1)
        .querySelector(this.componentArray[this.componentIndex].class2);
    } catch (err) {
      return document.getElementById(this.currentElementId);
    }
  }

  @computed get currentTooltipPosition() {
    return this.componentArray[this.componentIndex]?.position || "bottom";
  }

  changeTooltipClass() {
    let tooltip = document.getElementById("tooltip-element");
    tooltip.className = `tooltip_container tooltip_${this.currentTooltipPosition}`;
  }

  setTooltipPosition() {
    try {
      let tooltip = document.getElementById("tooltip-element");
      let boudingRect = this.currentElement.getBoundingClientRect();
      let elemLeft = boudingRect.x;
      let elemTop = boudingRect.y;
      let elemHeight = boudingRect.height;
      let elemWidth = boudingRect.width;
      let transformString = "";
      switch (this.currentTooltipPosition) {
        case "center":
          transformString = `translate(calc(${
            elemLeft + elemWidth / 2
          }px - 50%), ${elemTop + elemHeight / 2}px)`;
          break;
        case "left":
          transformString = `translate(calc(${elemLeft}px - 100%), calc(${
            elemTop + elemHeight / 2
          }px - 50%))`;
          break;
        case "top":
          transformString = `translate(calc(${
            elemLeft + elemWidth / 2
          }px - 50%), calc(${elemTop}px - 100%))`;
          break;
        case "right":
          transformString = `translate(${elemLeft + elemWidth}px, calc(${
            elemTop + elemHeight / 2
          }px - 50%))`;
          break;
        case "bottom":
        default:
          transformString = `translate(calc(${
            elemLeft + elemWidth / 2
          }px - 50%), ${elemTop + elemHeight}px)`;
      }
      this.changeTooltipClass();
      tooltip.style.transform = transformString;
    } catch (e) {
      // Handle error
    }
  }

  @action nextComponentWalkthrough = () => {
    if (window.innerWidth <= 1254 && this.componentIndex === 5) {
      this.componentIndex += 3;
    } else {
      this.componentIndex += 1;
    }
    var index = this.componentIndex;
    this.setTooltipPosition();
    if (this.componentIndex < this.componentArray.length) {
      if (this.componentArray[index].id.includes("Editor")) {
        try {
          document
            .getElementById(this.componentArray[index - 1].id)
            .querySelector(this.componentArray[index - 1].class1)
            .querySelector(this.componentArray[index - 1].class2)
            .classList.remove("zIndexForComponent");
        } catch (err) {
          document
            .getElementById(this.componentArray[index - 1].id)
            .classList.remove("zIndexForComponent");
        }
        document
          .getElementById(this.componentArray[index].id)
          .querySelector(this.componentArray[index].class1)
          .querySelector(this.componentArray[index].class2)
          .classList.add("zIndexForComponent");
      } else {
        try {
          document
            .getElementById(this.componentArray[index - 1].id)
            .querySelector(this.componentArray[index - 1].class1)
            .querySelector(this.componentArray[index - 1].class2)
            .classList.remove("zIndexForComponent");
        } catch (err) {
          document
            .getElementById(this.componentArray[index - 1].id)
            .classList.remove("zIndexForComponent");
        }
        document
          .getElementById(this.componentArray[index].id)
          .classList.add("zIndexForComponent");
      }
    } else {
      this.endWalkthrough(index - 1);
    }
  };

  @action endWalkthrough = (index = this.componentIndex) => {
    try {
      document
        .getElementById(this.componentArray[index].id)
        .querySelector(this.componentArray[index].class1)
        .querySelector(this.componentArray[index].class2)
        .classList.remove("zIndexForComponent");
    } catch (err) {
      document
        .getElementById(this.componentArray[index].id)
        .classList.remove("zIndexForComponent");
    }
    document
      .getElementById("walkthrough_container")
      .classList.add("hide_component");
    localStorage.setItem("walkthrough", true);
  };
}

export default WalkthroughStore;
